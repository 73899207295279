import { useContext, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useForm, Controller } from 'react-hook-form';

import { AppContext } from '../context/App/context';
import { DispatchEvents } from '../enums/dispatchEvent';
import { RequestForQuotationFormDataType } from '../types/request-for-quotation';
import { pageRoute } from '../config/routes/pages';

export const useRequestQuotationForm = () => {
  const [quotationFormDetails, setQuotationFormDetails] =
    useState<RequestForQuotationFormDataType>();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { dispatch } = useContext(AppContext);
  const recaptchaRef = useRef<any>(null);
  const router = useRouter();

  const {
    handleSubmit,
    control,
    formState,
    reset,
    formState: { errors },
    setValue
  } = useForm<RequestForQuotationFormDataType>({
    defaultValues: quotationFormDetails as RequestForQuotationFormDataType
  });

  async function onSubmit(data: RequestForQuotationFormDataType) {
    if (recaptchaRef.current.getValue()) {
      setQuotationFormDetails(data);
      const res = await fetch(`/api/send-email`, {
        body: JSON.stringify({
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          message: data.productsForRequest,
          subject: 'Subzero & Wolf Philippines | Request for Quotation',
          contact: data.contactNumber,
          city: data.city,
          address: data.address
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });

      const resData = await res.json();

      if (!res.ok) {
        dispatch({
          type: DispatchEvents.DISPLAY_TOAST,
          payload: {
            show: true,
            text: resData.error
          }
        });

        return;
      }

      dispatch({
        type: DispatchEvents.DISPLAY_STICKY_WIDGET_MODAL,
        payload: false
      });

      dispatch({
        type: DispatchEvents.IS_QUOTATION_POP_UP_OPEN,
        payload: false
      });

      reset();
      recaptchaRef.current.reset();
      router.push(pageRoute.THANKYOU);
    } else {
      showCaptchaToast();
    }
  }

  function showCaptchaToast() {
    dispatch({
      type: DispatchEvents.DISPLAY_TOAST,
      payload: {
        show: true,
        text: 'Please verify captcha'
      }
    });
  }

  return {
    Controller,
    control,
    formState,
    errors,
    isModalOpen,
    recaptchaRef,
    handleSubmit,
    onSubmit,
    setModalOpen,
    setValue
  };
};
