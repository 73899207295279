import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import Image from 'next/image';

import { AppContext } from '../../../context/App/context';
import { DispatchEvents } from '../../../enums/dispatchEvent';
import BookAppointmentIcon from '../../atoms/book-appointment-icon/book-appointment-icon';
import RequestQuoteIcon from '../../atoms/request-quote-icon/request-quote-icon';
import StickyWidgetColumnItemMobile from '../../atoms/sticky-widget-column-item-mobile/sticky-widget-column-item-mobile';

export function StickyWidgetMobile() {
  const { state, dispatch } = useContext(AppContext);
  const { isStickyWidgetModalOpen } = state;

  function handleModalClose() {
    dispatch({
      type: DispatchEvents.DISPLAY_STICKY_WIDGET_MODAL,
      payload: false
    });
  }

  function handleModalOpen() {
    dispatch({
      type: DispatchEvents.DISPLAY_STICKY_WIDGET_MODAL,
      payload: true
    });
  }

  return (
    <>
      <Transition.Root show={isStickyWidgetModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleModalClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto z-50">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="container relative bg-primary100 w-full overflow-hidden shadow-xl transform transition-all flex justify-center items-center z-50">
                  <div
                    className="absolute top-4 right-4"
                    onClick={handleModalClose}
                  >
                    <Image
                      alt="close icon"
                      height={18}
                      src="/close-icon.svg"
                      width={18}
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center h-full w-full bg-white mx-auto py-14">
                    <Image
                      alt="szwf logo"
                      height={30}
                      src="/szwf-logo.png"
                      width={250}
                    />
                    <StickyWidgetColumnItemMobile
                      isBooking
                      icon={<BookAppointmentIcon dimensions="100" />}
                      marginTop="mt-10"
                      title="Book an Appointment"
                    />
                    <StickyWidgetColumnItemMobile
                      isQuotation
                      icon={<RequestQuoteIcon dimensions="100" />}
                      marginTop="mt-4"
                      title="Request a quote"
                    />
                    <StickyWidgetColumnItemMobile
                      isDiscoverSeedToPlate
                      icon={
                        <div className="p-2">
                          <Image
                            alt="Seed To Plate"
                            height={60}
                            src="/icons/generations-icon.svg"
                            width={60}
                          />
                        </div>
                      }
                      marginTop="mt-4"
                      title="Fresh Food for Generations"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="fixed bottom-3 right-3 ml-auto translate h-16 w-16 bg-black rounded-full flex items-center justify-around px-2 z-50"
        onClick={handleModalOpen}
      >
        <div className="h-2 w-2 bg-white rounded-full" />
        <div className="h-2 w-2 bg-white rounded-full" />
        <div className="h-2 w-2 bg-white rounded-full" />
      </div>
    </>
  );
}

export default StickyWidgetMobile;
