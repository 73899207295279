import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../context/App/context';
import { DispatchEvents } from '../../../enums/dispatchEvent';

export function Toast() {
  const [displayToast, setDisplayToast] = useState(false);

  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    setDisplayToast(state.displayToast.show);

    if (state.displayToast.show) {
      triggerHideToast();
    }
  }, [state.displayToast.show]);

  function triggerHideToast() {
    setTimeout(() => {
      setDisplayToast(false);
      dispatch({
        type: DispatchEvents.DISPLAY_TOAST,
        payload: { show: false, text: '' }
      });
    }, 3000);
  }

  return (
    <div
      id="toast-default"
      className={`
      flex items-center justify-center p-3 sm:p-5 shadow fixed top-1/4 left-2/4 -translate-x-2/4 -translate-y-1/4 bg-slate-900/50 z-50
      ${displayToast ? 'fixed' : 'hidden'}
    `}
      role="alert"
    >
      <div className="text-subtitle sm:text-header6 text-white font-MuseoSans100 text-center">
        {state.displayToast.text}
      </div>
    </div>
  );
}

export default Toast;
