import { isEqual } from 'lodash';
import { useRouter } from 'next/router';

/* eslint-disable-next-line */
type MenuItemProps = {
  itemLabel: string;
  route: string;
  onClick?: () => void;
  isExternalLink?: boolean;
};

export function MenuItem(props: MenuItemProps) {
  const { isExternalLink = false, itemLabel, route, onClick } = props;

  const router = useRouter();

  function scrollDown() {
    window.scrollTo({
      top: 0
    });
  }

  function redirectLink() {
    if (onClick) onClick();
    if (isEqual(itemLabel, 'View Our Brochure') || isExternalLink) {
      window.open(route);
    } else {
      router.push(route);
      router.events.on('routeChangeComplete', scrollDown);
    }
  }

  function disableRedirect(e: any) {
    e.preventDefault();

    return false;
  }

  return (
    <button
      className="text-left text-caption leading-6 cursor-pointer hover:text-primaryGray font-MuseoSans300"
      onClick={redirectLink}
    >
      <a href={route} onClick={disableRedirect}>
        {itemLabel}
      </a>
    </button>
  );
}

export default MenuItem;
