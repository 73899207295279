import Link from 'next/link';

/* eslint-disable-next-line */
type ButtonLinkProps = {
  backgroundColor?: string;
  buttonStyle?: string;
  buttonTitle: string;
  ctaLink: string;
  hoverButtonStyle?: string;
  hoverContainerStyle?: string;
};

export function ButtonLink(props: ButtonLinkProps) {
  const {
    backgroundColor = 'bg-primaryGray',
    buttonStyle = 'border-white text-white',
    buttonTitle,
    ctaLink,
    hoverButtonStyle = 'group-hover:border-secondaryBlack group-hover:text-secondaryBlack',
    hoverContainerStyle = 'hover:bg-widgetGray'
  } = props;

  return (
    <div
      className={`px-2 py-2 ${backgroundColor} inline-block ${hoverContainerStyle} group flex justify-center w-fit`}
    >
      <Link href={ctaLink} passHref>
        <a
          className={`px-1 py-2 border-y ${buttonStyle} font-MuseoSans100 text-caption ${hoverButtonStyle} transition-all linear duration-300 !no-underline`}
        >
          {buttonTitle} &gt;
        </a>
      </Link>
    </div>
  );
}

export default ButtonLink;
