/*
  This ResponsiveImage component is a wrapper around Next.js's Image component and provides layout functionality in addition to Next's built in optimizations.

  Props:
  - alt: Descriptive text for an image to improve SEO. Affects Lighthouse score dramatically
  - blurImage: true by default. Blurs the image while it loads. Will be reassigned to the placeholder property
  - dimensions: height and width tailwind classname ONLY. The image will fill-up the height and width provided
  - objectFit: cover and contain ONLY. Serves as how the image would fill the parent element
  - priority: false by default. Loads the asset first among everything else. Should only be used for images above the fold
  - sizes: Improves image optimization by declaring how much viewport width does the image fill in the DOM.
    By default, it assumes to fill 100vw. When there are multiple images in a row, consider using the sizes property
    To get the sizes value, we will use useMediaQuery for this

    ex.
      for desktop view with images of 3 columns, it should have a value of 33vw,
      for tablet view with images of 2 columns, it should have a value of 50vw,
      for mobile view with images of 1 column, it should have a value of 100vw,
  - src: Image url
  - onClick: Handler function for clickable images

  Sample Usage:

  function SomeComponent(props) {
    const isMobile = useMediaQuery(MEDIA.SM);
    const isDesktop = useMediaQuery(MEDIA.LG);
    const sizes = isMobile ? "50vw" : "100vw";

    return (
      <ResponsiveImage
        alt="Miko Calo Recipe"
        dimensions="h-[500px] w-[500px] lg:h-screen lg:w-screen"
        objectFit="cover"
        sizes={sizes}
        src="/miko-calo-recipe.jpg"
        onClick={handleOpenModal}
      >
    )
  }
*/

import Image from 'next/image';

import { DEFAULT_BLUR_IMAGE } from '../../../constants/blurImage';

/* eslint-disable-next-line */
type ResponsiveImageProps = {
  alt: string;
  blurImage?: boolean;
  blurImageSrc?: string;
  dimensions: string;
  objectFit?: 'cover' | 'contain';
  objectPosition?: string;
  priority?: boolean;
  sizes?: string;
  src: string;
  onClick?: () => void;
};

export function ResponsiveImage(props: ResponsiveImageProps) {
  const {
    alt,
    blurImage = true,
    blurImageSrc = DEFAULT_BLUR_IMAGE,
    dimensions,
    objectFit,
    objectPosition = '50% 50%',
    priority = false,
    sizes = '100vw',
    src,
    onClick = undefined
  } = props;
  const placeholder = blurImage ? 'blur' : 'empty';

  return (
    <div
      className={`relative ${onClick && 'cursor-pointer'} ${dimensions} z-10`}
    >
      <Image
        alt={alt}
        blurDataURL={blurImageSrc}
        layout="fill"
        objectFit={objectFit}
        objectPosition={objectPosition}
        priority={priority}
        placeholder={placeholder}
        sizes={sizes}
        src={src}
        onClick={onClick}
      />
    </div>
  );
}

export default ResponsiveImage;
