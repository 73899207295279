import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { useRouter } from 'next/router';

import { generateProductEmailLink } from '../../../utils/products';
import { pages } from '../../../constants/pages';
import { contacts } from '../../../constants/contact';
import { linkRoutes } from '../../../config/routes/links';
import FooterColumnItem from '../footer-column-item/footer-column-item';
import FooterColumn from '../footer-column/footer-column';
import FooterContactIcon from '../footer-contact-icon/footer-contact-icon';
import CustomLink from '../custom-link/custom-link';
import { pageRoute } from '../../../config/routes/pages';
import SubscribeModal from '../../molecules/subscribe-modal/subscribe-modal';

export function Footer() {
  const { INSTAGRAM, TELEPHONE } = linkRoutes;
  const { CALL, VIBER, EMAIL } = contacts;

  const router = useRouter();
  const productEmailLink = generateProductEmailLink(
    '',
    router?.query?.product as string
  );

  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);

  function handleOpenSubscribeModal() {
    setIsSubscribeModalOpen(true);
  }

  const instagramColumnItem = (
    <FooterColumnItem extraMargin="mt-8">
      <li className="flex items-center">
        <div>
          <Link href={INSTAGRAM} passHref>
            <a target="_blank">
              <Image
                alt="ig icon"
                height={100}
                src="/instagram-icon.svg"
                width={100}
              />
            </a>
          </Link>
        </div>
        <div className="mx-5">
          <span className="font-semibold">FOLLOW US</span> on Instagram to be
          updated on real-time trends and happenings behind the scenes.
        </div>
      </li>
    </FooterColumnItem>
  );

  return (
    <div>
      <SubscribeModal
        isOpen={isSubscribeModalOpen}
        setIsOpen={setIsSubscribeModalOpen}
      />
      <div className="font-MuseoSans100 tracking-wider text-white w-full bg-footerGray">
        <div className="w-full max-w-defaultMax m-auto grid grid-cols-1 lg:grid-cols-12 justify-center text-caption p-5">
          <FooterColumn className="col-span-4">
            <FooterColumnItem categoryTitle="INSPIRATION AND PLANNING">
              <li>
                <CustomLink
                  ctaLink={pageRoute.FRIENDS_OF_KITCHEN}
                  text={pages.FRIENDS_OF_THE_KITCHEN}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.KITCHEN_GALLERY}
                  text={pages.KITCHEN_GALLERY}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.VIDEO_GALLERY}
                  text={pages.VIDEO_GALLERY}
                />
              </li>
              <li>
                <CustomLink ctaLink={pageRoute.RECIPES} text={pages.RECIPES} />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.TRADE_RESOURCES}
                  text={pages.TRADE_RESOURCES}
                />
              </li>
            </FooterColumnItem>
            <FooterColumnItem categoryTitle="SUPPORT AND SERVICE">
              <li>
                <CustomLink
                  ctaLink={pageRoute.CUSTOMER_SERVICE}
                  text={pages.CUSTOMER_SERVICE}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.MANUALS_AND_GUIDES}
                  text={pages.MANUALS_AND_GUIDES}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.USE_AND_CARE_VIDEOS}
                  text={pages.USE_AND_CARE_VIDEOS}
                />
              </li>
            </FooterColumnItem>
            <div className="hidden lg:block">{instagramColumnItem}</div>
          </FooterColumn>
          <FooterColumn className="col-span-3">
            <CustomLink
              ctaLink={pageRoute.NEWS_AND_EVENTS}
              style="mt-6 mb-3 sm:mt-3 sm:mb-3 font-semibold text-[13px] font-MuseoSans300"
              text={pages.NEWS_AND_EVENTS}
            />
            <FooterColumnItem categoryTitle="ABOUT US">
              <li>
                <CustomLink
                  ctaLink={pageRoute.ABOUT_FOCUS_GLOBAL}
                  text={pages.ABOUT_FOCUS_GLOBAL}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.ABOUT_SUBZERO_AND_WOLF}
                  text={pages.ABOUT_SUBZERO_AND_WOLF}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.ABOUT_BRAND_HERITAGE}
                  text={pages.ABOUT_BRAND_HERITAGE}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.ABOUT_SUSTAINABILITY}
                  text={pages.ABOUT_SUSTAINABILITY}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.ABOUT_REQUEST_FOR_QUOTATION}
                  text={pages.ABOUT_REQUEST_FOR_QUOTATION}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.ABOUT_SHOWROOM_LOCATIONS}
                  text={pages.ABOUT_SHOWROOM_LOCATIONS}
                />
              </li>
              <li>
                <CustomLink
                  ctaLink={pageRoute.CONTACT_US}
                  text={pages.CONTACT_US}
                />
              </li>
            </FooterColumnItem>
            <CustomLink
              ctaLink={pageRoute.PRIVACY_POLICY}
              style="my-3 font-semibold text-[13px] font-MuseoSans300"
              text={pages.PRIVACY_POLICY}
            />
          </FooterColumn>
          <FooterColumn className="col-span-5">
            <FooterColumnItem
              buttonText="VIEW ALL SHOWROOMS"
              categoryTitle="EXCLUSIVE SUB-ZERO AND WOLF SHOWROOM"
              ctaLink={pageRoute.ABOUT_SHOWROOM_LOCATIONS}
            >
              <li>
                Ground Floor, Manila Tower
                <p>The Residences at Greenbelt</p>
                <p>Esperanza Street, Makati City 1224, Philippines</p>
              </li>
              <li className="mt-1">
                <a
                  className="font-MuseoSans100 flex items-center"
                  href={TELEPHONE}
                >
                  <Image
                    alt="subzero and wolf telephone contact"
                    height={25}
                    src="/icons/telephone-icon.png"
                    width={30}
                  />
                  <span className="ml-1">(632) 8836 0389</span>
                </a>
              </li>
              <li className="mb-1">
                <a
                  className="font-MuseoSans100 flex items-center"
                  href={productEmailLink}
                >
                  <Image
                    alt="subzero and wolf mail contact"
                    height={25}
                    src="/icons/mail-icon.png"
                    width={30}
                  />
                  <span className="ml-1">subzero@focusglobalinc.com</span>
                </a>
              </li>
              <li>Mondays to Saturdays, 10am - 6pm</li>
              <li>(6pm - 7am by appointment)</li>
            </FooterColumnItem>
            <FooterColumnItem
              isActionButton
              buttonText="SUBSCRIBE"
              categoryTitle="NEWSLETTER"
              onClickHandleAction={handleOpenSubscribeModal}
            >
              <li>
                Sign up to our email newsletter to keep yourself posted on brand
                updates, showstopper recipes, and exciting special offers!
              </li>
            </FooterColumnItem>
          </FooterColumn>
        </div>
        <div className="w-full bg-secondaryBlack">
          <div className="w-full max-w-defaultMax m-auto py-10 px-5 xl:px-0 md:grid grid-cols-12">
            <div className="flex flex-col md:col-span-8">
              <div className="text-header6 font-extralight w-full">
                Customer Service Support
              </div>
              <div className="text-caption font-light mt-2 space-y-3">
                <div>
                  For quick support, reach us on{' '}
                  <a
                    className="underline hover:text-primaryBlue"
                    href={linkRoutes.FGI_VIBER}
                  >
                    Viber
                  </a>{' '}
                  or contact us via mobile at{' '}
                  <a
                    className="underline hover:text-primaryBlue"
                    href={linkRoutes.CALL}
                  >
                    (+63917) 120 0111
                  </a>{' '}
                  or landline at{' '}
                  <a
                    className="underline hover:text-primaryBlue"
                    href={linkRoutes.FGI_CALL}
                  >
                    (+632) 8634 8587
                  </a>
                  . Our support hours are Mon-Fri 7AM-5PM and Sat 7AM-4PM.
                  Alternatively, you can also email us at{' '}
                  <a
                    className="underline hover:text-primaryBlue"
                    href={linkRoutes.FGI_MAIL}
                  >
                    customerservice@focusglobalinc.com
                  </a>
                </div>
                <div>
                  <p>
                    © 2019 Sub-Zero, Inc. and Wolf Appliance, Inc. All Rights
                    Reserved.
                  </p>
                  <p>Exclusively distributed by Focus Global Inc.</p>
                </div>
              </div>
            </div>
            <div className="flex ml-auto mt-5 md:col-span-3 space-x-2">
              <FooterContactIcon
                content="fgicustomerservice"
                ctaLink={linkRoutes.FGI_VIBER}
                iconName={VIBER}
                shouldOpenInNewTab
              />
              <FooterContactIcon
                content="+639171200111"
                ctaLink={linkRoutes.CALL}
                iconName={CALL}
              />
              <FooterContactIcon
                content="customerservice@focusglobalinc.com"
                ctaLink={linkRoutes.FGI_MAIL}
                iconName={EMAIL}
                shouldOpenInNewTab
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
