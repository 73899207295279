import isEmpty from 'lodash/isEmpty';

import { ButtonLink } from '../button-link/button-link';
import CustomButton from '../custom-button/custom-button';

/* eslint-disable-next-line */
type FooterColumnItemProps = {
  buttonText?: string;
  categoryTitle?: string;
  children?: React.ReactNode | React.ReactNodeArray;
  ctaLink?: string;
  isActionButton?: boolean;
  extraMargin?: string;
  onClickHandleAction?: () => void;
};

export function FooterColumnItem(props: FooterColumnItemProps) {
  const {
    buttonText = '',
    categoryTitle = '',
    children,
    ctaLink = '',
    extraMargin = '',
    isActionButton = false,
    onClickHandleAction
  } = props;

  const button = isActionButton ? (
    <CustomButton
      buttonStyle="bg-primaryGray hover:bg-widgetGray "
      buttonText="SUBSCRIBE"
      textStyle="group-hover:border-secondaryBlack group-hover:text-secondaryBlack"
      handleClickButton={onClickHandleAction}
    />
  ) : (
    <ButtonLink buttonTitle={buttonText} ctaLink={ctaLink} />
  );

  const buttonContent = !isEmpty(buttonText) ? (
    <div className="mt-2">{button}</div>
  ) : (
    ''
  );

  return (
    <div className={extraMargin}>
      <div className="my-3 font-semibold text-[13px] font-MuseoSans300">
        {categoryTitle}
      </div>
      <ul className="font-light flex flex-col">{children}</ul>
      {buttonContent}
    </div>
  );
}

export default FooterColumnItem;
