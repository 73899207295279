export function GenerationsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 1738 1738"
    >
      <path
        fill="#737373"
        d="M909.825,1416.82l314.194,-314.201c92.24,-92.233 140.917,-212.24 146.039,-333.812l-460.233,460.233l0,187.78Zm453.097,-757.038c-9.015,-51.591 -26.065,-102.01 -51.15,-149.194l-401.947,401.955l0,200.335l453.097,-453.096Zm-96.712,-219.778c-12.876,-16.656 -26.94,-32.671 -42.191,-47.922l-0.065,-0.064c-22.299,-22.292 -46.228,-42.031 -71.402,-59.233l-242.727,242.727l0,220.869l356.385,-356.377Zm-187.934,-149.105c-53.589,-24.419 -110.679,-38.973 -168.451,-43.644l0,212.103l168.451,-168.459Zm-250.601,-43.644c-57.764,4.671 -114.862,19.217 -168.451,43.644l168.451,168.459l-0,-212.103Zm-242.727,85.53c-25.174,17.202 -49.103,36.941 -71.402,59.233l-0.065,0.064c-15.251,15.251 -29.315,31.266 -42.183,47.922l356.377,356.377l-0,-220.869l-242.727,-242.727Zm-159.22,177.803c-25.077,47.184 -42.135,97.603 -51.15,149.194l453.097,453.096l-0,-200.335l-401.947,-401.955Zm-58.278,258.219c5.122,121.572 53.791,241.579 146.031,333.812l314.194,314.201l-0,-187.78l-460.225,-460.233Zm914.653,391.897l-413.353,413.346l-413.345,-413.346c-113.706,-113.706 -170.563,-263.55 -170.563,-413.354c0,-144.417 52.851,-288.859 158.53,-400.943l-0,-0.369l12.033,-12.033c113.705,-113.706 263.541,-170.563 413.345,-170.563c149.804,0 299.64,56.857 413.353,170.563l12.033,12.033l0,0.369c105.679,112.084 158.522,256.526 158.522,400.943c-0,149.804 -56.849,299.648 -170.555,413.354Z"
      />
    </svg>
  );
}
