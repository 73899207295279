import { useContext, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { handleKeyDownLetters } from '../../../utils/inputs';
import FormLabel from '../form-label/form-label';
import MarginSpacing from '../margin-spacing/margin-spacing';
import { AppContext } from '../../../context/App/context';
import { DispatchEvents } from '../../../enums/dispatchEvent';
import { pageRoute } from '../../../config/routes/pages';

type MailchimpFormType = {
  emailValue?: string;
  onCloseHandler?: () => void;
};

export function MailchimpForm(props: MailchimpFormType) {
  const { emailValue = '' } = props;
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const emailRef = useRef<any>(null);
  const nameRef = useRef<any>(null);

  const { dispatch } = useContext(AppContext);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const res = await fetch(`/api/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: nameRef?.current.value,
        email: emailRef?.current.value ?? emailValue
      })
    });

    const resData = await res.json();

    if (!res.ok) {
      setLoading(false);
      props.onCloseHandler();
      dispatch({
        type: DispatchEvents.DISPLAY_TOAST,
        payload: {
          show: true,
          text: resData.message
        }
      });

      return;
    }

    // TO DO CALOY: Uncomment this once welcome email is provided
    // await fetch(`/api/welcome-email`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     name: data.name,
    //     email: data.email
    //   })
    // });

    setLoading(false);
    props.onCloseHandler();
    router.push(pageRoute.THANKYOU);
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <MarginSpacing margin="mt-2" />
      <FormLabel htmlFor="name" isRequired={true} label="Name" />
      <input
        className="p-2 w-full outline-none rounded-sm border border-headerBackground focus:border-2 focus:border-primaryGray font-MuseoSans100"
        defaultValue={nameRef?.current?.value}
        id="name"
        name="name"
        ref={nameRef}
        type="text"
        onKeyDown={handleKeyDownLetters}
      />
      <MarginSpacing margin="mt-2" />
      <FormLabel htmlFor="email" isRequired={true} label="Email" />
      <input
        autoCapitalize="off"
        autoCorrect="off"
        className="p-2 w-full outline-none rounded-sm border border-headerBackground focus:border-2 focus:border-primaryGray font-MuseoSans100"
        id="email"
        name="email"
        ref={emailRef}
        type="email"
      />
      <MarginSpacing margin="mt-3" />
      <button
        className="p-2 transition-color delay-100 duration-200 group bg-black text-primaryGray hover:text-white flex"
        type="submit"
      >
        <p className="px-2 py-1 border-y font-MuseoSans100 text-caption border-white">
          {loading ? 'Submitting...' : 'Submit'}
        </p>
      </button>
    </form>
  );
}

export default MailchimpForm;
