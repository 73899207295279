import Link from 'next/link';

import { linkRoutes } from '../../../config/routes/links';
import { pageRoute } from '../../../config/routes/pages';
import MenuItem from '../../atoms/menu-item/menu-item';
import { ProductMenuItems } from '../../../enums/menuItems';

export function MenuItems() {
  return (
    <div className="flex items-center h-full">
      <div className="flex items-center h-full group">
        <p className="cursor-pointer px-2 text-2caption tracking-[.15em] uppercase text-center font-BebasNeue text-footerGray">
          Products
        </p>
        <div className="animate-fadeIn absolute z-20 left-0 top-[85px] shadow-md hidden group-hover:flex hover:flex justify-center py-4 space-x-8 bg-secondaryGray w-full">
          <div className="flex space-x-4">
            <div className="flex flex-col">
              <Link href={pageRoute.SUBZERO} passHref>
                <a className="font-semibold text-2caption leading-6 cursor-pointer hover:text-primaryGray font-MuseoSans300">
                  Sub-Zero Refrigeration
                </a>
              </Link>
              <div className="flex flex-col">
                <MenuItem
                  itemLabel={ProductMenuItems.CLASSIC_REFRIGERATION}
                  route={pageRoute.CLASSIC_REFRIGERATION}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.DESIGNER_REFRIGERATION}
                  route={pageRoute.DESIGNER_REFRIGERATION}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.PRO_REFRIGERATION}
                  route={pageRoute.PRO_REFRIGERATION}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.UNDERCOUNTER_REFRIGERATION}
                  route={pageRoute.UNDERCOUNTER_REFRIGERATION}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.WINE_STORAGE}
                  route={pageRoute.WINE_STORAGE}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <Link href={pageRoute.WOLF} passHref>
                <a className="font-semibold text-2caption text-sm leading-6 cursor-pointer hover:text-primaryGray font-MuseoSans300">
                  Wolf Cooking
                </a>
              </Link>
              <div className="flex space-x-4">
                <div className="flex flex-col">
                  <MenuItem
                    itemLabel={ProductMenuItems.DUAL_FUEL_RANGES}
                    route={pageRoute.DUAL_FUEL_RANGES}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.INDUCTION_RANGES}
                    route={pageRoute.INDUCTION_RANGES}
                  />
                  <MenuItem itemLabel="Rangetops" route={pageRoute.RANGETOPS} />
                  <MenuItem
                    itemLabel={ProductMenuItems.GAS_COOKTOPS}
                    route={pageRoute.GAS_COOKTOPS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.INDUCTION_COOKTOPS}
                    route={pageRoute.INDUCTION_COOKTOPS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.MODULE_COOKTOPS}
                    route={pageRoute.MODULE_COOKTOPS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.BUILT_IN_OVENS}
                    route={pageRoute.BUILT_IN_OVENS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.CONVECTION_STEAM_OVENS}
                    route={pageRoute.CONVECTION_STEAM_OVENS}
                  />
                </div>

                <div className="flex flex-col">
                  <MenuItem
                    itemLabel={ProductMenuItems.SPEED_OVENS}
                    route={pageRoute.SPEED_OVENS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.MICROWAVES}
                    route={pageRoute.MICROWAVES}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.WARMING_DRAWERS}
                    route={pageRoute.WARMING_DRAWERS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.VACUUM_SEAL_DRAWERS}
                    route={pageRoute.VACUUM_SEAL_DRAWERS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.COFFEE_SYSTEMS}
                    route={pageRoute.COFFEE_SYSTEMS}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.VENTILATION}
                    route={pageRoute.VENTILATION}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.OUTDOOR_GRILLS}
                    route={pageRoute.OUTDOOR_GRILLS}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center h-full group">
        <p className="cursor-pointer px-2 text-2caption tracking-[.15em] uppercase text-center font-BebasNeue text-footerGray">
          Inspiration & Planning
        </p>
        <div className="animate-fadeIn top-[85px] shadow-md hidden z-20 group-hover:flex hover:flex space-x-8 absolute bg-secondaryGray justify-center py-4 px-10 ml-[-1vw]">
          <div className="flex flex-col">
            <MenuItem
              itemLabel="Friends of the Kitchen"
              route={pageRoute.FRIENDS_OF_KITCHEN}
            />
            <MenuItem
              itemLabel="Kitchen Gallery"
              route={pageRoute.KITCHEN_GALLERY}
            />
            <MenuItem
              itemLabel="Video Gallery"
              route={pageRoute.VIDEO_GALLERY}
            />
            <MenuItem itemLabel="Recipes" route={pageRoute.RECIPES} />
            <MenuItem
              itemLabel="Trade Resources"
              route={pageRoute.TRADE_RESOURCES}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center h-full group">
        <p className="cursor-pointer px-2 text-2caption tracking-[.15em] uppercase text-center font-BebasNeue text-footerGray">
          Support & Service
        </p>
        <div className="animate-fadeIn top-[85px] shadow-md hidden z-20 group-hover:flex hover:flex space-x-8 absolute bg-secondaryGray justify-center py-4 px-10 ml-[-1vw]">
          <div className="flex flex-col">
            <MenuItem
              itemLabel="Customer Service"
              route={pageRoute.CUSTOMER_SERVICE}
            />
            <MenuItem
              itemLabel="Manuals and Guides"
              route={pageRoute.MANUALS_AND_GUIDES}
            />
            <MenuItem
              itemLabel="Use and Care Videos"
              route={pageRoute.USE_AND_CARE_VIDEOS}
            />
          </div>
        </div>
      </div>

      <Link href={pageRoute.NEWS_AND_EVENTS} passHref>
        <a className="cursor-pointer px-2 text-2caption tracking-[.15em] uppercase text-center font-BebasNeue text-footerGray">
          News & Events
        </a>
      </Link>

      <div className="flex items-center h-full group">
        <p className="cursor-pointer px-2 text-2caption tracking-[.15em] uppercase text-center font-BebasNeue text-footerGray">
          About us
        </p>
        <div className="animate-fadeIn top-[85px] shadow-md hidden z-20 group-hover:flex hover:flex space-x-8 absolute bg-secondaryGray justify-center py-4 px-10 ml-[-1vw]">
          <div className="flex flex-col">
            <MenuItem
              itemLabel="About Focus Global"
              route={pageRoute.ABOUT_FOCUS_GLOBAL}
            />
            <MenuItem
              itemLabel="About Sub-Zero and Wolf"
              route={pageRoute.ABOUT_SUBZERO_AND_WOLF}
            />
            <MenuItem
              itemLabel="Brand Heritage"
              route={pageRoute.ABOUT_BRAND_HERITAGE}
            />
            <MenuItem
              itemLabel="Sustainability"
              route={pageRoute.ABOUT_SUSTAINABILITY}
            />
            <MenuItem
              itemLabel="View Our Brochure"
              route={linkRoutes.BROCHURE}
            />
            <MenuItem
              itemLabel="Request for Quotation"
              route={pageRoute.ABOUT_REQUEST_FOR_QUOTATION}
            />
            <MenuItem
              itemLabel="Showroom Locations"
              route={pageRoute.ABOUT_SHOWROOM_LOCATIONS}
            />
            <MenuItem itemLabel="Contact Us" route={pageRoute.CONTACT_US} />
          </div>
        </div>
      </div>

      <div className="flex items-center h-full group">
        <p className="text-2caption tracking-wider text-center uppercase bg-primaryGray !text-white px-3 py-2 font-BebasNeue">
          Stories of Freshness
        </p>
        <div className="animate-fadeIn top-[85px] shadow-md hidden z-20 group-hover:flex hover:flex space-x-8 absolute bg-secondaryGray justify-center py-4 px-10 ml-[-1vw]">
          <div className="flex flex-col">
            <MenuItem
              isExternalLink
              itemLabel="Fresh Food Matters"
              route={linkRoutes.FRESHFOOD}
            />
            <MenuItem
              isExternalLink
              itemLabel="Seed to Plate"
              route={linkRoutes.SEED_TO_PLATE}
            />
            <MenuItem
              isExternalLink
              itemLabel="Fresh Food for Generations"
              route={linkRoutes.GENERATIONS}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuItems;
