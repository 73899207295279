import Link from 'next/link';
import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { isEqual } from 'lodash';

import { MediaQuery, useMediaQuery } from '../../../hooks/useMediaQuery';
import { ENTER_KEY } from '../../../constants/searchTabs';
import { linkRoutes } from '../../../config/routes/links';
import { AppContext } from '../../../context/App/context';
import { DispatchEvents } from '../../../enums/dispatchEvent';
import SearchForm from '../../molecules/search-form/search-form';
import MenuItems from '../../molecules/menu-items/menu-items';
import MobileMenuItems from '../../molecules/mobile-menu-items/mobile-menu-items';

export function Menu() {
  const isMobile = useMediaQuery(MediaQuery.LG);
  const { state, dispatch } = useContext(AppContext);
  const [keyword, setKeyword] = useState('');
  const menuRef = useRef<any>(null);
  const router = useRouter();

  useEffect(() => {
    if (!state.showMobileMenu) return;
    function handleClick(event: { target: any }) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        dispatch({ type: DispatchEvents.SHOW_MOBILE_MENU, payload: false });
      }
    }
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [state.showMobileMenu]);

  function handleSearchOnEnter(keyboardKey: string) {
    if (isEqual(keyboardKey, ENTER_KEY)) {
      router.push(`/sitesearch.do?keyword=${keyword}`);
      dispatch({ type: DispatchEvents.SHOW_MOBILE_MENU, payload: false });
    }
  }

  function handleSearch() {
    router.push(`/sitesearch.do?keyword=${keyword}`);
    dispatch({ type: DispatchEvents.SHOW_MOBILE_MENU, payload: false });
  }

  const searchButton = (
    <button
      className="bg-primaryGray px-1"
      type="submit"
      onClick={handleSearch}
    >
      <Image
        alt="Search"
        height={15}
        src="/icons/search-white.png"
        width={15}
      />
    </button>
  );

  const menuItems = isMobile ? <MobileMenuItems /> : <MenuItems />;

  return (
    <div
      className="animate-slideFromTop absolute z-10 w-full left-0 top-[87px] py-4 px-8 space-y-4 bg-white shadow-md bg-opacity-95"
      ref={menuRef}
    >
      <SearchForm
        button={searchButton}
        className="flex items-center h-8 w-full"
        handleSearch={handleSearchOnEnter}
        setKeyword={setKeyword}
      />
      {menuItems}
    </div>
  );
}

export default Menu;
