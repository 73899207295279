import { useEffect, useContext, useRef } from 'react';

import { AppContext } from '../../../context/App/context';
import { DispatchEvents } from '../../../enums/dispatchEvent';
/* eslint-disable-next-line */
type SearchFormProps = {
  button?: React.ReactElement;
  className?: string;
  formStyle?: string;
  placeholder?: string;
  handleSearch: (e: any) => void;
  setKeyword: (e: any) => void;
};

export function SearchForm(props: SearchFormProps) {
  const {
    button,
    className = 'py-2 px-4 h-full',
    formStyle = 'font-BebasNeue',
    placeholder = 'SEARCH...',
    handleSearch,
    setKeyword
  } = props;

  const { dispatch, state } = useContext(AppContext);
  const searchRef = useRef<any>(null);

  useEffect(() => {
    if (!state.showSearchForm) return;

    function handleClick(event: any) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        dispatch({ type: DispatchEvents.SHOW_SEARCH_FORM, payload: false });
      }
    }

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, [state.showSearchForm]);

  return (
    <div className={`${className}`} ref={searchRef}>
      <input
        className={`py-2 px-4 h-full w-full outline-none ${formStyle}`}
        name="keyword"
        placeholder={placeholder}
        type="text"
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={(e) => handleSearch(e.key)}
      />
      {button}
    </div>
  );
}

export default SearchForm;
