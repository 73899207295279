import Image from 'next/image';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { LocalStorageKey } from '../../../enums/local-storage-key';
import { DEFAULT_BLUR_IMAGE } from '../../../constants/blurImage';
import { productPopup } from '../../../config/popups';
import { usePopups } from '../../../hooks/usePopups';
import { ProductImage } from '../../../interface/strapi';
import ButtonLink from '../button-link/button-link';
import CustomButton from '../custom-button/custom-button';

/* eslint-disable-next-line */
type ProductPopupProps = {
  content?: string;
  heading?: string;
  pop_up_image?: { data: ProductImage };
};

export function ProductPopup(props: ProductPopupProps) {
  const { content = '', heading = '', pop_up_image } = props;

  const { isModalOpen, onCloseHandler } = usePopups(
    3000,
    productPopup.expirationDay,
    LocalStorageKey.PRODUCT_POPUP_DATE
  );

  const router = useRouter();

  const contentAndHeadingIsEmpty = isEmpty(content) && isEmpty(heading);

  const popupContent = !contentAndHeadingIsEmpty ? (
    <div className="flex flex-col w-full sm:w-3/4 pt-14 pb-16">
      <div className="block w-4/5 sm:w-3/5 max-w-sm m-auto">
        <Image
          alt="subzero home"
          height={30}
          layout="responsive"
          objectFit="contain"
          src="/icons/brand-logo.png"
          width={'100%'}
        />
      </div>
      <div className="w-4/5 max-w-md m-auto h-full">
        <p className="font-MuseoSans500 text-subtitle mb-4">{heading}</p>
        <p className="font-MuseoSans500 text-subtitle leading-5">{content}</p>
      </div>
    </div>
  ) : (
    <Fragment />
  );

  const popupImageContainerStyle = !contentAndHeadingIsEmpty
    ? { width: '50%' }
    : { width: '100%' };

  const imageOnly = (
    <Image
      alt="product-popup"
      blurDataURL={DEFAULT_BLUR_IMAGE}
      height={pop_up_image.data.attributes.height}
      layout="responsive"
      placeholder="blur"
      src={pop_up_image.data.attributes.url}
      width={pop_up_image.data.attributes.width}
    />
  );

  const imageWithContent = (
    <Image
      alt="product-popup"
      blurDataURL={DEFAULT_BLUR_IMAGE}
      layout="fill"
      objectFit="cover"
      placeholder="blur"
      src={pop_up_image.data.attributes.url}
    />
  );

  const image = !contentAndHeadingIsEmpty ? imageWithContent : imageOnly;

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-50 inset-0 h-[70%] top-[5%] translate-y-[5%]">
          <div className="flex items-end sm:items-center justify-center md:min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-categoryCardGray sm:bg-white w-full sm:w-[80%] lg:w-[45%] xl:w-[600px] shadow-xl transform transition-all flex justify-center">
                <div className="flex flex-col h-full w-full relative bg-[#051F41] items-center justify-center">
                  <AiOutlineClose
                    className="absolute w-10 h-10 text-black hover:text-black/60 top-4 right-4 z-20"
                    onClick={onCloseHandler}
                  />
                  {popupContent}
                  <div
                    className={`relative ${
                      contentAndHeadingIsEmpty ? '' : 'hidden sm:block'
                    }`}
                    style={popupImageContainerStyle}
                  >
                    {image}
                  </div>
                  <div className="pt-1.5 pb-4">
                    <CustomButton
                      buttonStyle="bg-primaryGray hover:bg-widgetGray"
                      buttonText={'FIND OUT MORE'}
                      hasLink={false}
                      textStyle="group-hover:border-secondaryBlack group-hover:text-secondaryBlack text-white"
                      handleClickButton={() => {
                        onCloseHandler();
                        router.push('/news-and-events/070124');
                      }}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ProductPopup;
