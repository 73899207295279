import Link from 'next/link';
import Image from 'next/image';
import { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useRouter } from 'next/router';
import { isEqual } from 'lodash';
import { MapPin } from 'lucide-react';

import { ENTER_KEY } from '../../../constants/searchTabs';
import { pageRoute } from '../../../config/routes/pages';
import MenuItems from '../../molecules/menu-items/menu-items';
import Menu from '../menu/menu';
import SearchForm from '../../molecules/search-form/search-form';
import { linkRoutes } from '../../../config/routes/links';
import { AppContext } from '../../../context/App/context';
import { DispatchEvents } from '../../../enums/dispatchEvent';

export function Navbar() {
  const { state, dispatch } = useContext(AppContext);
  const router = useRouter();
  const [keyword, setKeyword] = useState('');

  function handleSearchOnEnter(keyboardKey: string) {
    if (isEqual(keyboardKey, ENTER_KEY))
      router.push(`/sitesearch.do?keyword=${keyword}`);
  }

  function handleSearch() {
    router.push(`/sitesearch.do?keyword=${keyword}`);
  }

  function handleShowSearch() {
    dispatch({
      type: DispatchEvents.SHOW_SEARCH_FORM,
      payload: !state.showSearchForm
    });
  }

  function handleShowMenu() {
    dispatch({ type: DispatchEvents.SHOW_MOBILE_MENU, payload: true });
  }

  const searchButton = (
    <button
      className="py-2 px-4 h-full bg-primaryBlue text-white text-caption"
      onClick={handleSearch}
    >
      Submit
    </button>
  );

  const searchForm = state.showSearchForm && (
    <SearchForm
      button={searchButton}
      className="animate-slideFromRight z-20 absolute flex items-center top-[110px] right-[5vw] 2xl:right-[20vw] bg-secondaryGray p-5 shadow-md"
      handleSearch={handleSearchOnEnter}
      setKeyword={setKeyword}
    />
  );

  const menu = state.showMobileMenu ? <Menu /> : null;

  return (
    <div className="w-full sticky top-0 shadow-sm z-50 bg-white bg-opacity-95 h-[87px]">
      <div className="flex flex-col h-full w-full m-auto items-center justify-center">
        <div className="hidden lg:flex items-center justify-center w-full text-caption bg-secondaryGray h-[25px] xl:-translate-x-2">
          <div className="flex justify-end md:max-w-defaultMax md:w-full space-x-4 px-12">
            <Link href={linkRoutes.TELEPHONE} passHref>
              <a className="cursor-pointer hover:text-primaryBlue font-MuseoSans500 tracking-widest text-caption text-secondaryBlack">
                +632 8705 9999
              </a>
            </Link>
            <span className="pl-1 pr-1">|</span>
            <Link href={linkRoutes.BROCHURE} passHref>
              <a
                className="cursor-pointer hover:text-primaryBlue font-MuseoSans500 tracking-widest text-caption text-secondaryBlack"
                target="_blank"
              >
                View Our Brochure
              </a>
            </Link>
            <span className="pl-1 pr-1">|</span>
            <Link href={linkRoutes.VIRTUALSHOWROOM_SZWF} passHref>
              <a
                className="cursor-pointer hover:text-primaryBlue font-MuseoSans500 tracking-widest text-caption text-secondaryBlack"
                target="_blank"
              >
                Virtual Showroom
              </a>
            </Link>
            <Link passHref href={linkRoutes.INSTAGRAM}>
              <a aria-label="Instagram" target="_blank">
                <FontAwesomeIcon
                  className="hover:text-primaryBlue cursor-pointer"
                  height={18}
                  icon={faInstagram}
                  width={18}
                />
              </a>
            </Link>
          </div>
        </div>

        <div className="flex w-full max-w-defaultMax justify-between items-center h-[60px] px-4 lg:px-12">
          <div>
            <Link passHref href="/">
              <div className="w-[200px] lg:w-[250px] cursor-pointer">
                <Image
                  alt="subzero home"
                  height={30}
                  layout="responsive"
                  objectFit="contain"
                  src="/icons/brand-logo.png"
                  width={'100%'}
                />
              </div>
            </Link>
          </div>

          <div className="hidden xl:flex items-center md:space-x-2 lg:space-x-4 h-full text-sm">
            <div className="flex items-center w-full h-full">
              <MenuItems />
            </div>

            <button
              className={`h-full flex items-center px-2 ${
                state.showSearchForm && 'border-b-2 border-black'
              }`}
              onClick={handleShowSearch}
            >
              <Image
                alt="subzero home"
                height={30}
                src="/icons/search.png"
                width={30}
              />
            </button>
            {searchForm}
          </div>

          <div className="flex space-x-4 xl:hidden translate-y-1">
            <div className="flex space-x-4">
              <div className="cursor-pointer hidden xs:block">
                <Link passHref href={pageRoute.ABOUT_SHOWROOM_LOCATIONS}>
                  <MapPin />
                </Link>
              </div>
              <div className="cursor-pointer hidden xs:block">
                <Link passHref href={linkRoutes.TELEPHONE}>
                  <Image
                    alt="subzero phone"
                    height={24}
                    src="/icons/whatsapp-icon.png"
                    width={24}
                  />
                </Link>
              </div>
            </div>

            <button className="cursor-pointer" onClick={handleShowMenu}>
              <Image
                alt="subzero menu"
                height={20}
                width={20}
                src="/icons/hamburger-icon.png"
              />
            </button>
          </div>
        </div>
        <div className="z-[100]">{menu}</div>
      </div>
    </div>
  );
}

export default Navbar;
