import { ReactChild, ReactNode } from 'react';

/* eslint-disable-next-line */
type MobileSubMenuProps = {
  children: ReactChild | ReactNode;
  isOpen: boolean;
};

export function MobileSubMenu(props: MobileSubMenuProps) {
  const { children, isOpen } = props;

  return (
    <div
      className={`animate-fadeIn ${
        isOpen ? 'flex' : 'hidden'
      } bg-secondaryGray justify-start py-4 px-4 md:px-10 w-full border-t-2 border-black shadow-md`}
    >
      <div className="flex flex-col max-h-[400px] overflow-auto">
        {children}
      </div>
    </div>
  );
}

export default MobileSubMenu;
