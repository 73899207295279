import { format, parseISO } from 'date-fns';

export function formatDate(date: string) {
  const parsedDate = format(parseISO(date), 'LLL d, y');

  return parsedDate;
}

export function getCurrentDate() {
  return format(new Date(), 'yyyy-MM-dd');
}
