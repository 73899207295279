import { isEqual } from 'lodash';
import { RefCallBack } from 'react-hook-form';

import {
  handleKeyDownLetters,
  handleKeyDownNumbers
} from '../../../utils/inputs';

/* eslint-disable-next-line */
type TextInputProps = {
  className?: string;
  field: FieldType;
  name: string;
  placeholder: string;
  shouldAcceptLettersAndNumbers?: boolean;
  type?: string;
};

type FieldType = {
  name: string;
  ref: RefCallBack;
  value: string;
  onBlur: () => void;
  onChange: (value: any) => void;
};

export function TextInput(props: TextInputProps) {
  const {
    className,
    field,
    name,
    placeholder,
    shouldAcceptLettersAndNumbers = false,
    type = 'text'
  } = props;

  const keyDownFunction = isEqual(type, 'number')
    ? handleKeyDownNumbers
    : shouldAcceptLettersAndNumbers
    ? null
    : handleKeyDownLetters;

  return (
    <input
      className={`p-2 w-full outline-none rounded-sm border border-headerBackground focus:border-2 focus:border-primaryGray font-MuseoSans100  ${className}`}
      defaultValue={field.value}
      placeholder={placeholder}
      type={type}
      onKeyDown={keyDownFunction as any}
      {...field}
    />
  );
}

export default TextInput;
