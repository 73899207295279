import Link from 'next/link';

/* eslint-disable-next-line */
type CustomButtonProps = {
  buttonText: string;
  buttonStyle?: string;
  ctaLink?: string;
  hasLink?: boolean;
  target?: '_blank' | '_self';
  textStyle?: string;
  handleClickButton?: () => void;
};

export function CustomButton(props: CustomButtonProps) {
  const {
    buttonText,
    buttonStyle = 'bg-black text-primaryGray hover:text-white',
    ctaLink,
    hasLink = false,
    target = '_self',
    textStyle = 'border-white',
    handleClickButton
  } = props;

  const customButton = hasLink ? (
    <Link href={ctaLink as string} passHref>
      <a
        className={`p-2 inline-block transition-color linear duration-300 group ${buttonStyle}`}
        target={target}
      >
        <p
          className={`px-2 py-1 border-y font-MuseoSans100 text-caption ${textStyle}`}
        >
          {buttonText}
        </p>
      </a>
    </Link>
  ) : (
    <button
      className={`p-2 inline-block transition-color linear duration-300 group ${buttonStyle}`}
      onClick={handleClickButton}
    >
      <p
        className={`px-2 py-1 border-y font-MuseoSans100 text-caption ${textStyle}`}
      >
        {buttonText}
      </p>
    </button>
  );

  return customButton;
}

export default CustomButton;
