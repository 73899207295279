import Image from 'next/image';
import Link from 'next/link';

import { linkRoutes } from '../../../config/routes/links';
import { strapiAssetBaseUrl } from '../../../config/routes/assets';
import { GenerationsIcon } from '../generations-icon/generations-icon';

export default function StickyWidgetImage() {
  return (
    <div className="group">
      <div className="w-16 h-16 group-hover:w-60 flex justify-end items-center bg-widgetGray transition-all duration-300 ease-linear sticky-widget cursor-pointer">
        <Link href={linkRoutes.GENERATIONS} passHref>
          <a
            className="hidden group-hover:flex text-caption whitespace-nowrap"
            target="_blank"
          >
            Fresh Food for Generations
          </a>
        </Link>
        <div className="p-3">
          <GenerationsIcon />
        </div>
      </div>
      <div className="w-0 group-hover:w-60 relative transition-width duration-300 ease-in bg-white">
        <div>
          <Image
            alt="Discover Seed To Plate"
            height={200}
            src={`${strapiAssetBaseUrl}/1_1_7a4cdaa09c.png`}
            width={400}
          />
        </div>
        <div className="flex flex-col justify-center w-full p-4">
          <p className="hidden group-hover:flex text-caption">
            Discover the legacy of exceptional meals with Sub-Zero and Wolf.
          </p>

          <Link href={linkRoutes.GENERATIONS} passHref>
            <a
              className="flex mt-2 justify-between items-center w-full cursor-pointer"
              target="_blank"
            >
              <p className="hidden group-hover:flex mt-2 justify-between items-center text-caption whitespace-nowrap">
                Find out more
              </p>
              <p className="hidden group-hover:flex ">&rarr;</p>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
