import Image from 'next/image';

import * as S from './styles';

/* eslint-disable-next-line */
type FooterContactIconProps = {
  ctaLink?: string;
  content: string;
  iconName: string;
  shouldOpenInNewTab?: boolean;
};

export function FooterContactIcon(props: FooterContactIconProps) {
  const { ctaLink, content, iconName, shouldOpenInNewTab = false } = props;

  const target = shouldOpenInNewTab ? '_blank' : '_self';

  return (
    <S.Container className="flex flex-col relative" content={content}>
      <a
        className="font-MuseoSans100 font-extralight text-caption text-center"
        href={ctaLink}
        rel="noreferrer"
        target={target}
      >
        <Image
          alt={`${iconName} icon`}
          height={55}
          src={`/${iconName.toLowerCase()}-icon.png`}
          width={55}
        />
        <div className="capitalize">{iconName}</div>
      </a>
    </S.Container>
  );
}

export default FooterContactIcon;
