import React, { useContext, useEffect, useState } from 'react';
import { PopupModal } from 'react-calendly';

import { DispatchEvents } from '../../../enums/dispatchEvent';
import { AppContext } from '../../../context/App/context';

function CalendlyPopupWidget() {
  const { state, dispatch } = useContext(AppContext);
  const [initialDocument, setDocument] = useState<Document | null>(null);

  useEffect(() => {
    setDocument(document);
  }, []);

  function handleCloseModal() {
    dispatch({
      type: DispatchEvents.DISPLAY_CALENDLY,
      payload: {
        ...state.calendlyProps,
        isOpen: false
      }
    });
  }

  return (
    <PopupModal
      open={state.calendlyProps.isOpen}
      rootElement={initialDocument?.getElementById('__next') as HTMLElement}
      url={state.calendlyProps.url}
      onModalClose={handleCloseModal}
    />
  );
}

export default CalendlyPopupWidget;
