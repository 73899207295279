export const pages = {
  FRIENDS_OF_THE_KITCHEN: 'Friends of the Kitchen',
  KITCHEN_GALLERY: 'Kitchen Gallery',
  VIDEO_GALLERY: 'Video Gallery',
  RECIPES: 'Recipes',
  TRADE_RESOURCES: 'Trade Resources',
  CUSTOMER_SERVICE: 'Customer Service',
  MANUALS_AND_GUIDES: 'Manuals and Guides',
  USE_AND_CARE_VIDEOS: 'Use and Care Videos',
  ABOUT_FOCUS_GLOBAL: 'About Focus Global',
  ABOUT_SUBZERO_AND_WOLF: 'About Sub-Zero & Wolf',
  ABOUT_BRAND_HERITAGE: 'Brand Heritage',
  ABOUT_SUSTAINABILITY: 'Sustainability',
  ABOUT_REQUEST_FOR_QUOTATION: 'Request for Quotation',
  ABOUT_SHOWROOM_LOCATIONS: 'Showroom Locations',
  CONTACT_US: 'Contact Us',
  PRIVACY_POLICY: 'PRIVACY POLICY',
  NEWS_AND_EVENTS: 'NEWS AND EVENTS'
};
