import { useContext } from 'react';
import { useRouter } from 'next/router';

import { linkRoutes } from '../../../config/routes/links';
import { AppContext } from '../../../context/App/context';
import { DispatchEvents } from '../../../enums/dispatchEvent';

/* eslint-disable-next-line */
type StickyWidgetColumnItemMobileProps = {
  description?: string;
  icon: JSX.Element;
  isBooking?: boolean;
  isDiscoverSeedToPlate?: boolean;
  isQuotation?: boolean;
  marginTop?: string;
  title: string;
};

export function StickyWidgetColumnItemMobile(
  props: StickyWidgetColumnItemMobileProps
) {
  const {
    description = '',
    icon,
    isBooking = false,
    isDiscoverSeedToPlate = false,
    isQuotation = false,
    marginTop = '',
    title
  } = props;

  const router = useRouter();
  const { dispatch } = useContext(AppContext);

  function handleClickWidget() {
    if (isBooking)
      dispatch({
        type: DispatchEvents.DISPLAY_CALENDLY,
        payload: {
          isOpen: true,
          url: linkRoutes.CALENDLY
        }
      });

    if (isQuotation)
      dispatch({
        type: DispatchEvents.IS_QUOTATION_POP_UP_OPEN,
        payload: true
      });

    if (isDiscoverSeedToPlate) router.push(linkRoutes.GENERATIONS);
  }

  return (
    <>
      <div
        className={`flex bg-widgetGray w-11/12 p-4 mx-4 ${marginTop}`}
        onClick={handleClickWidget}
      >
        {icon}
        <div className="flex flex-col items-start justify-center font-MuseoSans100">
          <div className="font-bold text-body">{title}</div>
          <p className="text-caption">{description}</p>
        </div>
      </div>
    </>
  );
}

export default StickyWidgetColumnItemMobile;
