import Link from 'next/link';

/* eslint-disable-next-line */
type CustomLinkProps = {
  ctaAdditionalLabel?: string;
  ctaLink: string;
  style?: string;
  text: string;
};

export function CustomLink(props: CustomLinkProps) {
  const { ctaAdditionalLabel, ctaLink, style, text } = props;

  const renderAdditionalLabel = ctaAdditionalLabel && (
    <p className="font-MuseoSans100 text-subtitle">{ctaAdditionalLabel}</p>
  );

  return (
    <Link href={ctaLink} passHref>
      <a className={style}>
        {text} {renderAdditionalLabel}
      </a>
    </Link>
  );
}

export default CustomLink;
