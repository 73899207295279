import { linkRoutes } from './links';

const SUBZERO_CATEGORY_ROUTE = '/subzero-refrigeration/';
const WOLF_CATEGORY_ROUTE = '/wolf-cooking/';
const FRIENDS_OF_KITCHEN_ROUTE = '/friends-of-the-kitchen';

export const pageRoute = {
  PRODUCTS: '/',
  SUBZERO: '/subzero-refrigeration',
  WINE_STORAGE: `${SUBZERO_CATEGORY_ROUTE}Wine_Storage`,
  CLASSIC_REFRIGERATION: `${SUBZERO_CATEGORY_ROUTE}Classic_Refrigeration`,
  DESIGNER_REFRIGERATION: `${SUBZERO_CATEGORY_ROUTE}Designer_Refrigeration`,
  PRO_REFRIGERATION: `${SUBZERO_CATEGORY_ROUTE}PRO_Refrigeration`,
  UNDERCOUNTER_REFRIGERATION: `${SUBZERO_CATEGORY_ROUTE}Undercounter_Refrigeration`,
  WOLF: '/wolf-cooking',
  CONVECTION_STEAM_OVENS: `${WOLF_CATEGORY_ROUTE}Convection_Steam_Ovens`,
  BUILT_IN_OVENS: `${WOLF_CATEGORY_ROUTE}Built_In_Ovens`,
  INDUCTION_RANGES: `${WOLF_CATEGORY_ROUTE}Induction_Ranges`,
  SPEED_OVENS: `${WOLF_CATEGORY_ROUTE}Speed_Ovens`,
  DUAL_FUEL_RANGES: `${WOLF_CATEGORY_ROUTE}Dual_Fuel_Ranges`,
  RANGETOPS: `${WOLF_CATEGORY_ROUTE}Rangetops`,
  GAS_COOKTOPS: `${WOLF_CATEGORY_ROUTE}Gas_Cooktops`,
  INDUCTION_COOKTOPS: `${WOLF_CATEGORY_ROUTE}Induction_Cooktops`,
  MODULE_COOKTOPS: `${WOLF_CATEGORY_ROUTE}Module_Cooktops`,
  COFFEE_SYSTEMS: `${WOLF_CATEGORY_ROUTE}Coffee_Systems`,
  MICROWAVES: `${WOLF_CATEGORY_ROUTE}Microwaves`,
  WARMING_DRAWERS: `${WOLF_CATEGORY_ROUTE}Warming_Drawers`,
  VACUUM_SEAL_DRAWERS: `${WOLF_CATEGORY_ROUTE}Vacuum_Seal_Drawers`,
  VENTILATION: `${WOLF_CATEGORY_ROUTE}Ventilation`,
  OUTDOOR_GRILLS: `${WOLF_CATEGORY_ROUTE}Outdoor_Grills`,
  FRIENDS_OF_KITCHEN: `${FRIENDS_OF_KITCHEN_ROUTE}`,
  FRIENDS_OF_KITCHEN_CHEF_TONY_BOY: `${FRIENDS_OF_KITCHEN_ROUTE}/chef-tony-boy-escalante`,
  FRIENDS_OF_KITCHEN_CHEF_FLORABEL_CO: `${FRIENDS_OF_KITCHEN_ROUTE}/chef-florabel-co`,
  FRIENDS_OF_KITCHEN_AR_GIL: `${FRIENDS_OF_KITCHEN_ROUTE}/ar-gil-coscolluela`,
  FRIENDS_OF_KITCHEN_AR_WILLIAM: `${FRIENDS_OF_KITCHEN_ROUTE}/ar-william-coscolluela`,
  FRIENDS_OF_KITCHEN_TWEETIE_DE_LEON: `${FRIENDS_OF_KITCHEN_ROUTE}/tweetie-de-leon-gonzales`,
  FRIENDS_OF_KITCHEN_HAPPY_ONGPAUCO: `${FRIENDS_OF_KITCHEN_ROUTE}/happy-ongpauco-tiu`,
  FRIENDS_OF_KITCHEN_ANTHONY_AND_MARICEL: `${FRIENDS_OF_KITCHEN_ROUTE}/anthony-and-maricel-pangilinan`,
  FRIENDS_OF_KITCHEN_FILMS: `${FRIENDS_OF_KITCHEN_ROUTE}/films`,
  FRIENDS_OF_KITCHEN_TV_APPEARANCES: `${FRIENDS_OF_KITCHEN_ROUTE}/tv-appearances`,
  KITCHEN_GALLERY: '/kitchen-gallery',
  VIDEO_GALLERY: '/video-gallery',
  RECIPES: '/recipes',
  TRADE_RESOURCES: '/trade-resources.do',
  CUSTOMER_SERVICE: '/customer-service.do',
  MANUALS_AND_GUIDES: '/manuals-and-guides.do',
  USE_AND_CARE_VIDEOS: '/use-and-care-videos.do',
  ABOUT_FOCUS_GLOBAL: '/about-focusglobal.do',
  ABOUT_SUBZERO_AND_WOLF: '/about-subzero.do',
  ABOUT_BRAND_HERITAGE: '/about-heritage.do',
  ABOUT_SUSTAINABILITY: '/about-sustainability.do',
  ABOUT_REQUEST_FOR_QUOTATION: '/about-quotation.do',
  ABOUT_SHOWROOM_LOCATIONS: '/about-showroom.do',
  CONTACT_US: '/contact.do',
  NEWS_AND_EVENTS_ONLY: '/news-and-events',
  NEWS_AND_EVENTS: '/news-and-events/all',
  NEWS_AND_EVENTS_FEATURED: '/news-and-events/featured',
  NEWS_ONLY: '/news-and-events/news',
  EVENTS_ONLY: '/news-and-events/events',
  NEWS_AND_EVENTS_PRODUCT_UPDATES: '/news-and-events/product-updates',
  PRIVACY_POLICY: '/privacypolicy.do',
  SITE_SEARCH: '/sitesearch.do',
  THANKYOU: '/thankyou.do'
};

export const supportAndServiceRoutes = [
  {
    tabLabel: 'Customer Service',
    route: pageRoute.CUSTOMER_SERVICE
  },
  {
    tabLabel: 'Manuals and Guides',
    route: pageRoute.MANUALS_AND_GUIDES
  },
  {
    tabLabel: 'Use and Care Videos',
    route: pageRoute.USE_AND_CARE_VIDEOS
  }
];

export const aboutRoutes = [
  {
    tabLabel: 'About Focus Global',
    route: pageRoute.ABOUT_FOCUS_GLOBAL
  },
  {
    tabLabel: 'About Sub-Zero and Wolf',
    route: pageRoute.ABOUT_SUBZERO_AND_WOLF
  },
  {
    tabLabel: 'Brand Heritage',
    route: pageRoute.ABOUT_BRAND_HERITAGE
  },
  {
    tabLabel: 'Sustainability',
    route: pageRoute.ABOUT_SUSTAINABILITY
  },
  {
    tabLabel: 'View Our Brochure',
    route: linkRoutes.BROCHURE
  },
  {
    tabLabel: 'Request for Quotation',
    route: pageRoute.ABOUT_REQUEST_FOR_QUOTATION
  },
  {
    tabLabel: 'Showroom Locations',
    route: pageRoute.ABOUT_SHOWROOM_LOCATIONS
  }
];

export const inspirationsAndPlanningRoutes = [
  {
    tabLabel: 'Friends of the Kitchen',
    route: pageRoute.FRIENDS_OF_KITCHEN
  },
  {
    tabLabel: 'Kitchen Gallery',
    route: pageRoute.KITCHEN_GALLERY
  },
  {
    tabLabel: 'Video Gallery',
    route: pageRoute.VIDEO_GALLERY
  },
  {
    tabLabel: 'Recipes',
    route: pageRoute.RECIPES
  },
  {
    tabLabel: 'Trade Resources',
    route: pageRoute.TRADE_RESOURCES
  }
];

export const friendsOfTheKitchenRoutes = [
  {
    tabLabel: 'CHEF TONY BOY ESCALANTE',
    slug: 'chef-tony-boy-escalante',
    route: pageRoute.FRIENDS_OF_KITCHEN_CHEF_TONY_BOY
  },
  {
    tabLabel: 'CHEF FLORABEL CO',
    slug: 'chef-florabel-co',
    route: pageRoute.FRIENDS_OF_KITCHEN_CHEF_FLORABEL_CO
  },
  {
    tabLabel: 'AR. GIL COSCOLLUELA',
    slug: 'ar-gil-coscolluela',
    route: pageRoute.FRIENDS_OF_KITCHEN_AR_GIL
  },
  {
    tabLabel: 'AR. WILLIAM COSCOLLUELA',
    slug: 'ar-william-coscolluela',
    route: pageRoute.FRIENDS_OF_KITCHEN_AR_WILLIAM
  },
  {
    tabLabel: 'TWEETIE DE LEON-GONZALES',
    slug: 'tweetie-de-leon-gonzales',
    route: pageRoute.FRIENDS_OF_KITCHEN_TWEETIE_DE_LEON
  },
  {
    tabLabel: 'HAPPY ONGPAUCO-TIU',
    slug: 'happy-ongpauco-tiu',
    route: pageRoute.FRIENDS_OF_KITCHEN_HAPPY_ONGPAUCO
  },
  {
    tabLabel: 'ANTHONY AND MARICEL PANGILINAN',
    slug: 'anthony-and-maricel-pangilinan',
    route: pageRoute.FRIENDS_OF_KITCHEN_ANTHONY_AND_MARICEL
  },
  {
    tabLabel: 'SUB-ZERO AND WOLF IN MOVIES',
    slug: 'films',
    route: pageRoute.FRIENDS_OF_KITCHEN_FILMS
  },
  {
    tabLabel: 'SUB-ZERO AND WOLF IN TV SERIES',
    slug: 'tv-appearances',
    route: pageRoute.FRIENDS_OF_KITCHEN_TV_APPEARANCES
  }
];

export const newsRoutes = [
  {
    tabLabel: 'All',
    slug: 'all',
    route: pageRoute.NEWS_AND_EVENTS
  },
  {
    tabLabel: 'Featured Stories',
    slug: 'featured',
    route: pageRoute.NEWS_AND_EVENTS_FEATURED
  },
  {
    tabLabel: 'News',
    slug: 'news',
    route: pageRoute.NEWS_ONLY
  },
  {
    tabLabel: 'Events',
    slug: 'events',
    route: pageRoute.EVENTS_ONLY
  },
  {
    tabLabel: 'Product Updates',
    slug: 'product-updates',
    route: pageRoute.NEWS_AND_EVENTS_PRODUCT_UPDATES
  }
];
