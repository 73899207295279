/* eslint-disable-next-line */
type MarginSpacingProps = {
  margin: string;
};

/* 
  This component is created to avoid overriding components 
  with different margins in their respective classnames
*/
export function MarginSpacing(props: MarginSpacingProps) {
  const { margin } = props;

  return <div className={margin} />;
}

export default MarginSpacing;
