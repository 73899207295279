import { ActionType } from '../../types/context';
import { DispatchEvents } from '../../enums/dispatchEvent';

export const initialState = {
  categoryFilters: []
};

export function reducer(state: typeof initialState, action: ActionType) {
  const { payload, type } = action;

  switch (type) {
    case DispatchEvents.SET_CATEGORY_FILTERS:
      return {
        ...state,
        categoryFilters: payload
      };

    default:
      throw new Error();
  }
}
