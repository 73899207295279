import { Fragment, Dispatch, SetStateAction } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import MailchimpForm from '../../atoms/mailchimp-form/mailchimp-form';

type SubscribeModalProps = {
  emailValue?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export function SubscribeModal(props: SubscribeModalProps) {
  const { emailValue = '', isOpen, setIsOpen } = props;

  function onCloseHandler() {
    setIsOpen(false);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-50 inset-0 h-[70%] top-[5%] translate-y-[5%]">
          <div className="flex items-end sm:items-center justify-center md:min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-white w-full sm:w-3/4 lg:w-1/2 xl:w-1/3 transform transition-all flex justify-center h-full p-3">
                <div className="flex flex-col w-full">
                  <div className="border-b border-gray-400/50 text-left pb-4">
                    <h5 className="font-MuseoSans100 text-header6">
                      Subscribe
                    </h5>
                    <p className="font-MuseoSans100 text-caption">
                      <span className="text-primaryRed">*</span> indicates
                      required
                    </p>
                  </div>
                  <div>
                    <MailchimpForm
                      emailValue={emailValue}
                      onCloseHandler={onCloseHandler}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SubscribeModal;
