import Link from 'next/link';
import { useContext, useState } from 'react';

import { MediaQuery, useMediaQuery } from '../../../hooks/useMediaQuery';
import { ProductMenuItems } from '../../../enums/menuItems';
import { DispatchEvents } from '../../../enums/dispatchEvent';
import { pageRoute } from '../../../config/routes/pages';
import { linkRoutes } from '../../../config/routes/links';
import MenuItem from '../../atoms/menu-item/menu-item';
import MobileSubMenu from '../../atoms/mobile-sub-menu/mobile-sub-menu';
import { AppContext } from '../../../context/App/context';

export function MobileMenuItems() {
  const isMobile = useMediaQuery(MediaQuery.SM);
  const { dispatch } = useContext(AppContext);

  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isInspirationsOpen, setIsInspirationsOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isStoriesOpen, setIsStoriesOpen] = useState(false);

  function handleOpenProductsMenu() {
    setIsProductsOpen((prevState) => !prevState);
  }

  function handleOpenInspirationsMenu() {
    setIsInspirationsOpen((prevState) => !prevState);
  }

  function handleOpenSupportMenu() {
    setIsSupportOpen((prevState) => !prevState);
  }

  function handleOpenAboutMenu() {
    setIsAboutOpen((prevState) => !prevState);
  }

  function handleOpenStories() {
    setIsStoriesOpen((prevState) => !prevState);
  }

  function handleSelectMenuItem() {
    dispatch({ type: DispatchEvents.SHOW_MOBILE_MENU, payload: false });
  }

  return (
    <div className="flex flex-col items-start h-full space-y-2 -z-50 tracking-wider">
      <Link href="/" passHref>
        <a
          className="cursor-pointer px-2 text-subtitle tracking-wider text-primaryGray uppercase text-center py-2 font-BebasNeue"
          onClick={handleSelectMenuItem}
        >
          Home
        </a>
      </Link>

      <div className="flex flex-col items-start w-full h-full">
        <button onClick={handleOpenProductsMenu}>
          <p className="cursor-pointer px-2 uppercase text-center py-2 text-subtitle tracking-wider text-primaryGray font-BebasNeue">
            Products
          </p>
        </button>
        <MobileSubMenu isOpen={isProductsOpen}>
          <div
            className={`flex space-x-4 ${
              isMobile && 'flex-col space-x-0 space-y-4'
            }`}
          >
            <div className="flex flex-col">
              <Link href={pageRoute.SUBZERO} passHref>
                <a
                  className="font-semibold text-caption leading-6 cursor-pointer hover:text-primaryGray font-MuseoSans500"
                  onClick={handleSelectMenuItem}
                >
                  Sub-Zero Refrigeration
                </a>
              </Link>
              <div className="flex flex-col">
                <MenuItem
                  itemLabel={ProductMenuItems.CLASSIC_REFRIGERATION}
                  route={pageRoute.CLASSIC_REFRIGERATION}
                  onClick={handleSelectMenuItem}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.DESIGNER_REFRIGERATION}
                  route={pageRoute.DESIGNER_REFRIGERATION}
                  onClick={handleSelectMenuItem}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.PRO_REFRIGERATION}
                  route={pageRoute.PRO_REFRIGERATION}
                  onClick={handleSelectMenuItem}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.UNDERCOUNTER_REFRIGERATION}
                  route={pageRoute.UNDERCOUNTER_REFRIGERATION}
                  onClick={handleSelectMenuItem}
                />
                <MenuItem
                  itemLabel={ProductMenuItems.WINE_STORAGE}
                  route={pageRoute.WINE_STORAGE}
                  onClick={handleSelectMenuItem}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <Link href={pageRoute.WOLF} passHref>
                <a
                  className="font-semibold text-caption leading-6 cursor-pointer hover:text-primaryGray font-MuseoSans500"
                  onClick={handleSelectMenuItem}
                >
                  Wolf Cooking
                </a>
              </Link>
              <div className="flex space-x-4">
                <div className="flex flex-col">
                  <MenuItem
                    itemLabel={ProductMenuItems.DUAL_FUEL_RANGES}
                    route={pageRoute.DUAL_FUEL_RANGES}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.INDUCTION_RANGES}
                    route={pageRoute.INDUCTION_RANGES}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.RANGETOPS}
                    route={pageRoute.RANGETOPS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.GAS_COOKTOPS}
                    route={pageRoute.GAS_COOKTOPS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.INDUCTION_COOKTOPS}
                    route={pageRoute.INDUCTION_COOKTOPS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.MODULE_COOKTOPS}
                    route={pageRoute.MODULE_COOKTOPS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.BUILT_IN_OVENS}
                    route={pageRoute.BUILT_IN_OVENS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.CONVECTION_STEAM_OVENS}
                    route={pageRoute.CONVECTION_STEAM_OVENS}
                    onClick={handleSelectMenuItem}
                  />
                </div>
                <div className="flex flex-col">
                  <MenuItem
                    itemLabel={ProductMenuItems.SPEED_OVENS}
                    route={pageRoute.SPEED_OVENS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.MICROWAVES}
                    route={pageRoute.MICROWAVES}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.WARMING_DRAWERS}
                    route={pageRoute.WARMING_DRAWERS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.VACUUM_SEAL_DRAWERS}
                    route={pageRoute.VACUUM_SEAL_DRAWERS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.COFFEE_SYSTEMS}
                    route={pageRoute.COFFEE_SYSTEMS}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.VENTILATION}
                    route={pageRoute.VENTILATION}
                    onClick={handleSelectMenuItem}
                  />
                  <MenuItem
                    itemLabel={ProductMenuItems.OUTDOOR_GRILLS}
                    route={pageRoute.OUTDOOR_GRILLS}
                    onClick={handleSelectMenuItem}
                  />
                </div>
              </div>
            </div>
          </div>
        </MobileSubMenu>
      </div>

      <div className="flex flex-col items-start w-full h-full">
        <button onClick={handleOpenInspirationsMenu}>
          <p className="cursor-pointer px-2 uppercase text-center py-2 shrink text-subtitle tracking-wider text-primaryGray font-BebasNeue">
            Inspiration & Planning
          </p>
        </button>
        <MobileSubMenu isOpen={isInspirationsOpen}>
          <MenuItem
            itemLabel="Friends of the Kitchen"
            route={pageRoute.FRIENDS_OF_KITCHEN}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Kitchen Gallery"
            route={pageRoute.KITCHEN_GALLERY}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Video Gallery"
            route={pageRoute.VIDEO_GALLERY}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Recipes"
            route={pageRoute.RECIPES}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Trade Resources"
            route={pageRoute.TRADE_RESOURCES}
            onClick={handleSelectMenuItem}
          />
        </MobileSubMenu>
      </div>

      <div className="flex flex-col items-start w-full h-full">
        <button onClick={handleOpenSupportMenu}>
          <p className="cursor-pointer px-2 uppercase text-center py-2 shrink text-subtitle tracking-wider text-primaryGray font-BebasNeue">
            Support & Service
          </p>
        </button>
        <MobileSubMenu isOpen={isSupportOpen}>
          <MenuItem
            itemLabel="Customer Service"
            route={pageRoute.CUSTOMER_SERVICE}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Manuals and Guides"
            route={pageRoute.MANUALS_AND_GUIDES}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Use and Care Videos"
            route={pageRoute.USE_AND_CARE_VIDEOS}
            onClick={handleSelectMenuItem}
          />
        </MobileSubMenu>
      </div>

      <Link href={pageRoute.NEWS_AND_EVENTS} passHref>
        <a
          className="cursor-pointer px-2 uppercase text-center py-2 shrink text-subtitle tracking-wider text-primaryGray font-BebasNeue"
          onClick={handleSelectMenuItem}
        >
          News & Events
        </a>
      </Link>

      <div className="flex flex-col items-start w-full h-full">
        <button onClick={handleOpenAboutMenu}>
          <p className="cursor-pointer px-2 uppercase text-center py-2 shrink text-subtitle tracking-wider text-primaryGray font-BebasNeue">
            About us
          </p>
        </button>
        <MobileSubMenu isOpen={isAboutOpen}>
          <MenuItem
            itemLabel="About Focus Global"
            route={pageRoute.ABOUT_FOCUS_GLOBAL}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="About Sub-Zero and Wolf"
            route={pageRoute.ABOUT_SUBZERO_AND_WOLF}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Brand Heritage"
            route={pageRoute.ABOUT_BRAND_HERITAGE}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Sustainability"
            route={pageRoute.ABOUT_SUSTAINABILITY}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="View Our Brochure"
            route={linkRoutes.BROCHURE}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Request for Quotation"
            route={pageRoute.ABOUT_REQUEST_FOR_QUOTATION}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Showroom Locations"
            route={pageRoute.ABOUT_SHOWROOM_LOCATIONS}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            itemLabel="Contact Us"
            route={pageRoute.CONTACT_US}
            onClick={handleSelectMenuItem}
          />
        </MobileSubMenu>
      </div>

      <Link href={linkRoutes.VIRTUALSHOWROOM_SZWF} passHref>
        <a
          className="cursor-pointer px-2 uppercase text-center py-2 text-subtitle tracking-wider text-primaryGray font-BebasNeue"
          target="_blank"
          onClick={handleSelectMenuItem}
        >
          Virtual Showroom
        </a>
      </Link>

      <div className="flex flex-col items-start w-full h-full">
        <button onClick={handleOpenStories}>
          <p className="text-subtitle tracking-wider uppercase bg-primaryGray text-white px-4 py-2 font-BebasNeue ">
            Stories of Freshness
          </p>
        </button>
        <MobileSubMenu isOpen={isStoriesOpen}>
          <MenuItem
            isExternalLink
            itemLabel="Fresh Food Matters"
            route={linkRoutes.FRESHFOOD}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            isExternalLink
            itemLabel="Seed to Plate"
            route={linkRoutes.SEED_TO_PLATE}
            onClick={handleSelectMenuItem}
          />
          <MenuItem
            isExternalLink
            itemLabel="Fresh Food for Generations"
            route={linkRoutes.GENERATIONS}
            onClick={handleSelectMenuItem}
          />
        </MobileSubMenu>
      </div>
    </div>
  );
}

export default MobileMenuItems;
