import { createContext, Dispatch } from 'react';

import { ActionType } from '../../types/context';
import { initialState } from './reducer';

export const ProductContext = createContext<{
  state: typeof initialState;
  dispatch: Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null
});
