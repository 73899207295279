import { useContext } from 'react';

import { linkRoutes } from '../../../config/routes/links';
import { DispatchEvents } from '../../../enums/dispatchEvent';
import { AppContext } from '../../../context/App/context';

/* eslint-disable-next-line */
type StickyWidgetProps = {
  isBooking?: boolean;
  isQuotation?: boolean;
  icon: JSX.Element;
  text: string;
};

export function StickyWidget(props: StickyWidgetProps) {
  const { icon, isBooking = false, isQuotation = false, text } = props;

  const { dispatch } = useContext(AppContext);

  function handleClickWidget() {
    if (isBooking) {
      dispatch({
        type: DispatchEvents.DISPLAY_CALENDLY,
        payload: {
          isOpen: true,
          url: linkRoutes.CALENDLY
        }
      });
    }
    if (isQuotation) {
      dispatch({
        type: DispatchEvents.IS_QUOTATION_POP_UP_OPEN,
        payload: true
      });

      setTimeout(() => {
        dispatch({
          type: DispatchEvents.IS_QUOTATION_POP_UP_OPEN,
          payload: false
        });
      }, 1000);
    }
  }

  return (
    <>
      <div
        className="flex justify-end items-center w-16 h-16 bg-widgetGray transition-all duration-300 ease-linear sticky-widget cursor-pointer"
        onClick={handleClickWidget}
      >
        <div className="sticky-widget-text text-caption whitespace-nowrap opacity-0">
          {text}
        </div>
        {icon}
      </div>
    </>
  );
}

export default StickyWidget;
