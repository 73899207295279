import { ActionType } from '../../types/context';
import { linkRoutes } from '../../config/routes/links';
import { CalendlyStateType, DisplayToast } from '../../types/app';
import { DispatchEvents } from '../../enums/dispatchEvent';

export const initialState = {
  calendlyProps: {
    isOpen: false,
    url: linkRoutes.CALENDLY
  },
  displayToast: {
    show: false,
    text: ''
  },
  showMobileMenu: false,
  isStickyWidgetModalOpen: false,
  isQuotationPopupOpen: false,
  showSearchForm: false
};

export function reducer(state: typeof initialState, action: ActionType) {
  const { payload, type } = action;

  switch (type) {
    case DispatchEvents.IS_QUOTATION_POP_UP_OPEN:
      return {
        ...state,
        isQuotationPopupOpen: payload
      };
    case DispatchEvents.DISPLAY_TOAST:
      return {
        ...state,
        displayToast: payload as unknown as DisplayToast
      };
    case DispatchEvents.SHOW_MOBILE_MENU:
      return {
        ...state,
        showMobileMenu: payload
      };
    case DispatchEvents.DISPLAY_CALENDLY:
      return {
        ...state,
        calendlyProps: payload as CalendlyStateType
      };
    case DispatchEvents.SHOW_SEARCH_FORM:
      return {
        ...state,
        showSearchForm: payload
      };
    case DispatchEvents.DISPLAY_STICKY_WIDGET_MODAL:
      return {
        ...state,
        isStickyWidgetModalOpen: payload
      };
    default:
      throw new Error();
  }
}
