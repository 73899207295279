import {
  Control,
  Controller,
  FieldError,
  Validate,
  ValidationRule
} from 'react-hook-form';

import { RequestForQuotationFormDataType } from '../../../types/request-for-quotation';
import { ContactUsFormDataType } from '../../../types/contact-list';
import { errorMessage } from '../../../constants/forms';
import FormErrorMessage from '../../atoms/form-error-message/form-error-message';
import TextInput from '../../atoms/text-input/text-input';

/* eslint-disable-next-line */
type FormFieldWrapperProps = { 
  control:
    | Control<RequestForQuotationFormDataType | any>
    | Control<ContactUsFormDataType, any>;
  customErrorMessage?: string;
  error: FieldError;
  fieldName: string;
  placeHolder: string;
  rules?: FormFieldRules;
  shouldAcceptLettersAndNumbers?: boolean;
  type?: string;
};

type FormFieldRules = {
  pattern?: ValidationRule<RegExp>;
  required: string | ValidationRule<boolean>;
  validate?: Validate<string> | Record<string, Validate<string>>;
};

export function FormFieldWrapper(props: FormFieldWrapperProps) {
  const {
    control,
    customErrorMessage,
    error,
    fieldName,
    placeHolder,
    rules,
    shouldAcceptLettersAndNumbers = false,
    type = 'text'
  } = props;

  const errorMessageContent = customErrorMessage ?? errorMessage;
  const required = rules?.required ? '*' : '';

  return (
    <div className="flex flex-col">
      <Controller
        control={control}
        name={fieldName as any}
        rules={rules}
        render={({ field }) => (
          <TextInput
            field={{ ...field }}
            name={fieldName}
            placeholder={`${required}${placeHolder}`}
            shouldAcceptLettersAndNumbers={shouldAcceptLettersAndNumbers}
            type={type}
          />
        )}
      />
      <FormErrorMessage errors={error} message={errorMessageContent} />
    </div>
  );
}

export default FormFieldWrapper;
