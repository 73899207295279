/* eslint-disable-next-line */
type FooterColumnProps = {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
};

export function FooterColumn(props: FooterColumnProps) {
  const { children, className = '' } = props;

  return (
    <div
      className={`flex flex-col lg:py-10 lg:justify-self-center ${className}`}
    >
      {children}
    </div>
  );
}

export default FooterColumn;
