import { useMediaQuery, MediaQuery } from '../../../hooks/useMediaQuery';
import BookAppointmentIcon from '../../atoms/book-appointment-icon/book-appointment-icon';
import MarginSpacing from '../../atoms/margin-spacing/margin-spacing';
import RequestQuoteIcon from '../../atoms/request-quote-icon/request-quote-icon';
import StickyWidget from '../../atoms/sticky-widget/sticky-widget';
import StickyWidgetImage from '../../atoms/sticky-widget-image/sticky-widget-image';
import StickyWidgetMobile from '../sticky-widget-mobile/sticky-widget-mobile';
import * as S from './styles';

export function StickyWidgets() {
  const isMobile = useMediaQuery(MediaQuery.SM);

  const stickyWidget = isMobile ? (
    <StickyWidgetMobile />
  ) : (
    <S.Container>
      <StickyWidget
        isBooking
        icon={<BookAppointmentIcon />}
        text="Book an Appointment"
      />
      <MarginSpacing margin="mb-px" />
      <StickyWidget
        isQuotation
        icon={<RequestQuoteIcon />}
        text="Request a Quote"
      />
      <MarginSpacing margin="mb-px" />
      <StickyWidgetImage />
    </S.Container>
  );

  return stickyWidget;
}

export default StickyWidgets;
