/* eslint-disable-next-line */
type FormLabelProps = {
  htmlFor: string;
  isRequired: boolean;
  label: string;
};

export function FormLabel(props: FormLabelProps) {
  const { htmlFor, isRequired, label } = props;

  const requiredSymbol = isRequired ? '*' : '';

  return (
    <div className="w-full text-left">
      <label className="font-MuseoSans500 text-subtitle" htmlFor={htmlFor}>
        {label}
        <span className="text-primaryRed">{requiredSymbol}</span>:
      </label>
    </div>
  );
}

export default FormLabel;
