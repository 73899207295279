import styled from 'styled-components';

export const Container = styled.div.attrs({
  className:
    'flex flex-col items-end fixed right-0 top-1/4 font-MuseoSans100 z-20'
})`
  span {
    vertical-align: middle;
  }

  .sticky-widget:hover {
    width: 200px;
    background: #757575;
    color: white;
  }

  .sticky-widget:hover rect {
    fill: #757575;
  }

  .sticky-widget:hover path {
    fill: #eeeeee;
  }

  .sticky-widget-text {
    display: none;
    opacity: 0;
  }

  .sticky-widget:hover .sticky-widget-text {
    display: flex;
    opacity: 1;
  }
`;
