import React, { useReducer } from 'react';

import { AppContext } from './context';
import { initialState, reducer } from './reducer';

type ContextProps = {
  children: React.ReactNode;
};

export default function AppContextProvider({ children }: ContextProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}
