import { isEqual } from 'lodash';
import { KeyboardEvent } from 'react';

import { lettersRegex } from './regex';

export const handleKeyDownNumbers = (e: KeyboardEvent<HTMLDivElement>) => {
  return isEqual(e.key, 'e') && e.preventDefault();
};

export const handleKeyDownLetters = (e: KeyboardEvent<HTMLDivElement>) => {
  return !lettersRegex.test(e.key) && e.preventDefault();
};
