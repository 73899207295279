import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useContext } from 'react';
import { XIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import ReCAPTCHA from 'react-google-recaptcha';
import { FieldError } from 'react-hook-form';

import ResponsiveImage from '../responsive-image/responsive-image';
import { emailRegex, lettersRegex } from '../../../utils/regex';
import { DispatchEvents } from '../../../enums/dispatchEvent';
import { useRequestQuotationForm } from '../../../hooks/useRequestQuotationForm';
import { errorMessage } from '../../../constants/forms';
import { API_ASSETS_ROUTE } from '../../../config/routes/assets';
import { AppContext } from '../../../context/App/context';
import TextInput from '../text-input/text-input';
import CustomButton from '../custom-button/custom-button';
import RequiredTooltip from '../required-tooltip/required-tooltip';
import FormFieldWrapper from '../../organisms/form-field-wrapper/form-field-wrapper';
import FormErrorMessage from '../form-error-message/form-error-message';

export function QuotationPopup() {
  const {
    Controller,
    control,
    errors,
    isModalOpen,
    recaptchaRef,
    handleSubmit,
    onSubmit,
    setModalOpen
  } = useRequestQuotationForm(true);

  const { state, dispatch } = useContext(AppContext);

  function onCloseHandler() {
    setModalOpen(false);
    dispatch({
      type: DispatchEvents.IS_QUOTATION_POP_UP_OPEN,
      payload: false
    });
  }

  useEffect(() => {
    if (state.isQuotationPopupOpen) setModalOpen(true);
  }, [state.isQuotationPopupOpen]);

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-50 inset-0 h-[95%] top-2/4 -translate-y-2/4 overflow-scroll">
          <div className="flex items-end sm:items-center justify-center md:min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-white w-full sm:w-[80%] md:w-[55%] transform transition-all flex justify-center h-full">
                <ResponsiveImage
                  alt="pop up image"
                  blurImageSrc="/icons/blur-placeholder.png"
                  dimensions="w-2/3 w-full hidden lg:flex bg-black"
                  objectFit="cover"
                  src={`${API_ASSETS_ROUTE}/pop-up-image.jpeg`}
                />

                <div className="w-full flex flex-col items-center justify-center">
                  <div className="mt-6">
                    <Image
                      alt="Brand Logo"
                      blurDataURL="/icons/blur-placeholder.png"
                      height={30}
                      placeholder="blur"
                      src="/icons/brand-logo.png"
                      width={250}
                    />
                  </div>

                  <div className="mt-4 text-primaryGray">
                    <p className="uppercase text-header6">
                      Request for Quotation
                    </p>
                    <p className="text-caption pt-2">
                      Please fill out the form below for our team
                      <br /> to be able to get back to you as soon as possible.
                    </p>
                  </div>

                  <form
                    action="#"
                    className="w-full px-4 md:px-12 space-y-2 mt-4 text-caption"
                    method="POST"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="text-left w-full m-auto">
                      <RequiredTooltip />
                    </div>
                    <div className="flex">
                      <div className="flex flex-col w-3/5">
                        <Controller
                          control={control}
                          name="firstName"
                          rules={{
                            pattern: lettersRegex,
                            required: true
                          }}
                          render={({ field }) => (
                            <TextInput
                              field={field}
                              name="firstName"
                              placeholder="*Name"
                            />
                          )}
                        />
                        <FormErrorMessage
                          errors={errors.firstName as FieldError}
                          message={errorMessage}
                        />
                      </div>
                      <div className="flex flex-col w-2/3">
                        <Controller
                          control={control}
                          name="lastName"
                          rules={{
                            pattern: lettersRegex,
                            required: true
                          }}
                          render={({ field }) => (
                            <TextInput
                              field={field}
                              name="lastName"
                              placeholder="*Last Name"
                            />
                          )}
                        />
                        <FormErrorMessage
                          errors={errors.lastName as FieldError}
                          message={errorMessage}
                        />
                      </div>
                    </div>
                    <FormFieldWrapper
                      control={control}
                      error={errors.contactNumber as FieldError}
                      fieldName="contactNumber"
                      placeHolder="Contact Number"
                      rules={{ required: true }}
                      type="number"
                    />
                    <FormFieldWrapper
                      shouldAcceptLettersAndNumbers
                      control={control}
                      customErrorMessage="Invalid email format"
                      error={errors.email as FieldError}
                      fieldName="email"
                      placeHolder="E-mail"
                      rules={{
                        required: true,
                        validate: (value) =>
                          value.match(emailRegex) || 'Invalid email format'
                      }}
                    />
                    <FormFieldWrapper
                      shouldAcceptLettersAndNumbers
                      control={control}
                      error={errors.address as FieldError}
                      fieldName="address"
                      placeHolder="Address"
                    />
                    <FormFieldWrapper
                      shouldAcceptLettersAndNumbers
                      control={control}
                      error={errors.city as FieldError}
                      fieldName="city"
                      placeHolder="City"
                      rules={{ required: true }}
                    />
                    <div className="text-left">
                      <Controller
                        control={control}
                        name="productsForRequest"
                        rules={{
                          required: true
                        }}
                        render={({ field }) => (
                          <textarea
                            className="p-2 w-full outline-none rounded-sm border border-headerBackground font-MuseoSans100 focus:border-2 focus:border-primaryGray"
                            placeholder="*Message"
                            rows={2}
                            {...field}
                          />
                        )}
                      />
                      <FormErrorMessage
                        errors={errors.productsForRequest as FieldError}
                        message={errorMessage}
                      />
                    </div>
                    <ReCAPTCHA
                      className="mt-3"
                      ref={recaptchaRef}
                      sitekey={process.env.NEXT_PUBLIC_SZWF_SITE_KEY as string}
                    />
                    <div className="py-4 flex justify-end">
                      <CustomButton
                        buttonText="Submit"
                        handleClickButton={handleSubmit(onSubmit)}
                      />
                    </div>
                  </form>
                </div>
                <XIcon
                  className="h-4 text-primaryGray cursor-pointer absolute top-3 md:top-4 right-3 md:right-5"
                  onClick={onCloseHandler}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default QuotationPopup;
