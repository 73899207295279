import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';

import CalendlyPopupWidget from '../../atoms/calendly-pop-up-widget/calendly-pop-up-widget';
import Footer from '../../atoms/footer/footer';
import ProductPopup from '../../atoms/product-popup/product-popup';
import QuotationPopup from '../../atoms/quotation-popup/quotation-popup';
import Toast from '../../atoms/toast/toast';
import StickyWidgets from '../../molecules/sticky-widgets/sticky-widgets';
import NavBar from '../../organisms/navbar/navbar';
/* eslint-disable-next-line */
type MainProps = {
  children: React.ReactNode | React.ReactNodeArray;
};

export function MainTemplate(props: MainProps) {
  const { children } = props;
  const [productPopupContent, setProductPopupContent] = useState<any>(null);

  useEffect(() => {
    const getProductPopupData = async () => {
      const productPopupData = await fetch('/api/product-pop-up');
      const attributes = await productPopupData.json();

      setProductPopupContent(attributes);
    };
    getProductPopupData();
  }, []);

  const productPopup = !isEmpty(productPopupContent) ? (
    <ProductPopup
      content={productPopupContent?.content}
      heading={productPopupContent?.heading}
      pop_up_image={productPopupContent?.pop_up_image}
    />
  ) : (
    <Fragment />
  );

  return (
    <div>
      <StickyWidgets />
      {productPopup}
      <CalendlyPopupWidget />
      <QuotationPopup />
      <Toast />
      <NavBar />
      {children}
      <Footer />
    </div>
  );
}

export default MainTemplate;
