import React, { useReducer } from 'react';

import { ProductContext } from './context';
import { initialState, reducer } from './reducer';

type ContextProps = {
  children: React.ReactNode;
};

export default function ProductContextProvider({ children }: ContextProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProductContext.Provider value={{ state, dispatch }}>
      {children}
    </ProductContext.Provider>
  );
}
