import Head from 'next/head';

export function SzwfMetaTags() {
  return (
    <Head>
      <title>Sub-Zero &amp; Wolf Philippines</title>
      <link href="/favicon.ico" rel="icon" type="image/x-icon" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta
        content="upgrade-insecure-requests"
        httpEquiv="Content-Security-Policy"
      />
      <meta content="Sub-Zero and Wolf Products" name="description" />
      <meta
        content="Sub-Zero and Wolf Products"
        key="title"
        property="og:title"
      />
      <meta
        name="google-site-verification"
        content={process.env['NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION']}
      />
      <SzwfMetaTags />
    </Head>
  );
}

export default SzwfMetaTags;
