import { differenceInDays } from 'date-fns';
import { useEffect, useState } from 'react';

import { getCurrentDate } from '../utils/dateUtils';

export const usePopups = (
  delayTimer: number,
  expirationDay: number,
  popupDateEnum: string
) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const popupDateFromLocal: string = localStorage.getItem(
      popupDateEnum
    ) as string;

    const pastExpirationDate =
      differenceInDays(new Date(), new Date(popupDateFromLocal)) >
      expirationDay;

    const hasPopupBeenDisplayedStatus = pastExpirationDate;

    if (hasPopupBeenDisplayedStatus) {
      setTimeout(() => {
        setModalOpen(true);
      }, delayTimer);
    }
  }, []);

  function onCloseHandler() {
    setModalOpen(false);
    localStorage.setItem(popupDateEnum, getCurrentDate());
  }

  return {
    isModalOpen,
    onCloseHandler,
    setModalOpen
  };
};
