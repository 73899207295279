export enum QueryOperators {
  ASC = 'asc',
  DESC = 'desc',
  EQUAL = '$eq',
  NOT_EQUAL = '$ne',
  LESS_THAN = '$lt',
  LESS_THAN_OR_EQUAL_TO = '$lte',
  GREATER_THAN = '$gt',
  GREATER_THAN_OR_EQUAL_TO = '$gte',
  IN = '$in', // included
  NOT_IN = '$notIn', // not included
  CONTAINS = '$contains', // case sensitive
  NOT_CONTAINS = '$notContains', // case sensitive
  CONTAINSI = '$containsi', // contains, not case sensitive
  NOT_CONTAINSI = '$notContainsi', // does not contain, not case sensitive
  NULL = '$null',
  NOT_NULL = '$notNull',
  BETWEEN = '$between',
  STARTS_WITH = '$startsWith',
  ENDS_WITH = '$endsWith',
  OR = '$or', // Joins the filters in an "or" expression
  AND = '$and' // Joins the filters in an "and" expression
}

export enum QueryKeys {
  CATEGORY = 'category',
  SRP = 'srp',
  PAGE_SIZE = 'pageSize',
  PAGE = 'page',
  FEATURED_PRODUCT = 'is_featured_product',
  BESTSELLER = 'is_best_seller',
  DECLARED_VALUE = 'declared_value',
  UPDATED_AT = 'updatedAt',
  PRODUCT_NAME = 'product_name',
  FOCUS_CATEGORY = 'focus_category',
  BRAND = 'brand',
  SUBCATEGORY = 'focus_subcategories.subcategory',
  VISIBLE_ON_WEBSITE = 'visible_on_website',
  PRIORITY = 'priority'
}
