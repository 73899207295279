import { useEffect } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import TagManager from 'react-gtm-module';

import { progressbarConfig } from '../config/progress-bar';
import MainTemplate from '../components/template/main/main';
import AppContextProvider from '../context/App/provider';
import ProductContextProvider from '../context/Products/provider';
import SzwfMetaTags from '../components/atoms/szwf-meta-tags/szwf-meta-tags';
import { GOOGLE_TAG_MANAGER } from '../config/google-tag-manager';

import './styles.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type NextPageWithLayout = NextPage & {
  getLayout?: (
    page: React.ReactElement,
    pageProps?: unknown
  ) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER.CONTAINER_ID });
  }, []);

  const getLayout =
    Component.getLayout ?? ((page) => <MainTemplate>{page}</MainTemplate>);

  const pageWithLayout = getLayout(
    <main>
      <NextNProgress {...progressbarConfig} />
      <Component {...pageProps} />
    </main>
  );

  return (
    <AppContextProvider>
      <ProductContextProvider>
        <SzwfMetaTags />
        {pageWithLayout}
      </ProductContextProvider>
    </AppContextProvider>
  );
}

export default CustomApp;
