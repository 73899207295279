export const linkRoutes = {
  TELEPHONE: 'tel:+632-8705-9999',
  BROCHURE: 'https://online.flipbuilder.com/guhg/lpjl/',
  VIRTUALSHOWROOM_SZWF: 'https://my.matterport.com/show/?m=Boe2AFEuzJe',
  VIRTUALSHOWROOM_SIEMATIC: 'https://my.matterport.com/show/?m=Qe2BBJyfEQs',
  INSTAGRAM: 'https://www.instagram.com/subzerowolfph',
  FRESHFOOD: 'https://fresh.subzero-wolf.com.ph/',
  MAIL: 'mailto:subzero@focusglobalinc.com',
  FGI_MAIL: 'mailto:customerservice@focusglobalinc.com',
  VIBER: 'viber://chat?number=+639171200111',
  FGI_VIBER: 'https://tinyurl.com/fgicustomerservice',
  CALL: 'tel:+639171200111',
  FGI_CALL: 'tel:+63286348587',
  TEXT: 'sms:+639171200111',
  WHATSAPP: 'https://wa.me/+639171200111',
  CALENDLY: 'https://calendly.com/fgi-kitchen-bookings',
  CALENDLY_MAKATI:
    'https://calendly.com/fgi-kitchen-bookings/makati-showroom-visit',
  CALENDLY_TAGUIG:
    'https://calendly.com/fgi-kitchen-bookings/bgc-showroom-visit',
  CALENDLY_MANDALUYONG:
    'https://calendly.com/fgi-kitchen-bookings/mandaluyong-showroom-visit',
  CALENDLY_CEBU:
    'https://calendly.com/fgi-kitchen-bookings/cebu-showroom-visit',
  BOOK_APPOINTMENT: 'https://calendly.com/fgi-kitchen-bookings',
  INSPIRATION_AND_PLANNING_ARTICLE:
    '/friends-of-the-kitchen/chef-tony-boy-escalante',
  SEED_TO_PLATE: 'https://www.seedtoplate.subzero-wolf.com.ph/',
  GENERATIONS: 'https://generations.subzero-wolf.com.ph/',
  REBATE_PROGRAM: '/news-and-events/070124'
};
