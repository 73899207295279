import { useState, useEffect } from 'react';

export enum MediaQuery {
  XS = '(max-width: 365px)',
  SM = '(max-width: 640px)',
  MD = '(max-width: 768px)',
  LG = '(max-width: 1024px)',
  XL = '(max-width: 1280px)',
  XXL = '(max-width: 1536px)'
}

export const useMediaQuery = (query: string = MediaQuery.LG) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};
