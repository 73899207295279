import styled from 'styled-components';

export const Container = styled.div<{ content: string }>`
  ::after {
    content: ${({ content }) => `"${content}"`};
    font-size: 10px;
    position: absolute;
    background: black;
    font-color: white;
    padding: 5px;
    top: -30%;
    left: -20%;
    opacity: 0;
  }

  :hover::after {
    opacity: 1;
  }
`;
