import { QueryModel } from '@proj-fgi/types';
import isEmpty from 'lodash/isEmpty';

import { QueryOperators, QueryKeys } from '../enums/query';
import { INCH_TO_MM_CONVERSION_FACTOR } from '../constants/products';

export function queryProducts(urlQuery) {
  const queries: QueryModel[] = [];
  const hasCategoryFilter = !isEmpty(urlQuery?.category);

  // Category Query
  const categories = urlQuery?.categories?.split(',');
  if (hasCategoryFilter) {
    queries.push({
      key: QueryKeys.CATEGORY,
      operator: QueryOperators.IN,
      value: categories
    });
  }

  return queries;
}

export function queryToString(query: string) {
  return query.replace(/_/g, ' ');
}

export function stringToQuery(query: string) {
  return query.replace(/ /g, '_').replace(/-/g, '_');
}

export function productNameToSlug(productName: string) {
  return productName
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/"/g, '-inch')
    .replace(/''/g, '-inch')
    .replace(',', '')
    .replace('/', '-');
}

export function checkIfProductIsDomestic(modelNumber: string) {
  const isDomesticProduct = !modelNumber.includes('ICB');
  const dimensionsUnit = !isDomesticProduct ? `mm` : 'in';
  const lengthUnit = !isDomesticProduct ? `mm` : 'feet';

  return { dimensionsUnit, isDomesticProduct, lengthUnit };
}

export function inchToMillimeter(measurement: number) {
  const converted = measurement * INCH_TO_MM_CONVERSION_FACTOR;
  return converted.toFixed(2);
}

export function millimeterToInch(measurement: number) {
  const converted = measurement / INCH_TO_MM_CONVERSION_FACTOR;
  return converted.toFixed(2);
}

export function generateProductEmailLink(
  modelNumber?: string,
  productName?: string
) {
  const modelNumberValue = isEmpty(modelNumber) ? '' : modelNumber + ' - ';

  return productName
    ? `mailto:subzero@focusglobalinc.com?&subject=Product Inquiry - ${modelNumber}&body=Hi%0A%0AI found this item interesting from the Sub-Zero and Wolf website: ${modelNumberValue}${productName}%0A%0AKindly send me more information and a price quotation for this model.%0A%0AThanks!`
    : `mailto:subzero@focusglobalinc.com?&subject=Sub-Zero and Wolf Inquiry`;
}
