export enum ProductMenuItems {
  CLASSIC_REFRIGERATION = 'Classic Refrigeration',
  DESIGNER_REFRIGERATION = 'Designer Refrigeration',
  PRO_REFRIGERATION = 'PRO Refrigeration',
  UNDERCOUNTER_REFRIGERATION = 'Undercounter Refrigeration',
  WINE_STORAGE = 'Wine Storage',
  DUAL_FUEL_RANGES = 'Dual Fuel Ranges',
  INDUCTION_RANGES = 'Induction Ranges',
  RANGETOPS = 'Rangetops',
  GAS_COOKTOPS = 'Gas Cooktops',
  INDUCTION_COOKTOPS = 'Induction Cooktops',
  MODULE_COOKTOPS = 'Module Cooktops',
  BUILT_IN_OVENS = 'Built-In Ovens',
  CONVECTION_STEAM_OVENS = 'Convection Steam Ovens',
  SPEED_OVENS = 'Speed Ovens',
  MICROWAVES = 'Microwaves',
  WARMING_DRAWERS = 'Warming Drawers',
  VACUUM_SEAL_DRAWERS = 'Vacuum Seal Drawers',
  COFFEE_SYSTEMS = 'Coffee Systems',
  VENTILATION = 'Ventilation',
  OUTDOOR_GRILLS = 'Outdoor Grills'
}
