type BookAppointmentIconProps = {
  dimensions?: string;
};

export function BookAppointmentIcon(props: BookAppointmentIconProps) {
  const { dimensions = '64' } = props;

  return (
    <svg
      width={dimensions}
      height={dimensions}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={60} height={60} fill="#EEEEEE" />
      <path
        d="M41.9156 17.3831L38.0422 17.3831V15.8929C38.0422 15.4035 37.6387 15 37.1493 15C36.66 15 36.2565 15.4035 36.2565 15.8929V17.3831L23.7435 17.3831L23.7435 15.8929C23.7435 15.4035 23.34 15 22.8507 15C22.3613 15 21.9578 15.4035 21.9578 15.8929L21.9578 17.3831L18.0844 17.3831C16.9405 17.3831 16 18.3236 16 19.4675L16 43.2987C16 44.4426 16.9405 45.3831 18.0844 45.3831L41.9156 45.3831C43.0595 45.3831 44 44.4426 44 43.2987L44 19.4675C44 18.3236 43.0595 17.3831 41.9156 17.3831ZM18.0844 19.1689L21.9578 19.1689V20.6591C21.9578 21.1484 22.3613 21.552 22.8507 21.552C23.34 21.552 23.7435 21.1484 23.7435 20.6591V19.1689L36.2565 19.1689V20.6591C36.2565 21.1484 36.66 21.552 37.1493 21.552C37.6387 21.552 38.0422 21.1484 38.0422 20.6591V19.1689L41.9156 19.1689C42.0776 19.1689 42.2143 19.3055 42.2143 19.4675L42.2143 24.5325L17.7857 24.5325L17.7857 19.4675C17.7857 19.3055 17.9224 19.1689 18.0844 19.1689ZM41.9156 43.5974L18.0844 43.5974C17.9224 43.5974 17.7857 43.4607 17.7857 43.2987L17.7857 26.3182L42.2143 26.3182L42.2143 43.2987C42.2143 43.4607 42.0776 43.5974 41.9156 43.5974ZM36.0182 30.773C36.3454 31.1353 36.3264 31.7009 35.9737 32.0377L29.0182 38.5928C28.8529 38.7485 28.6337 38.8343 28.4049 38.8311C28.1825 38.8248 27.9632 38.7422 27.7948 38.5928L24.0263 35.0182C23.8452 34.8466 23.7435 34.6114 23.7435 34.3636C23.7435 33.8711 24.1471 33.4675 24.6396 33.4675C24.8652 33.4675 25.0844 33.5533 25.2497 33.7059L28.4049 36.699L34.7503 30.7285C35.1126 30.3981 35.6782 30.4203 36.0182 30.773Z"
        fill="#737373"
      />
    </svg>
  );
}

export default BookAppointmentIcon;
