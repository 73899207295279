import { isEqual } from 'lodash';
import { Fragment } from 'react';
import { FieldError } from 'react-hook-form';

/* eslint-disable-next-line */
type FormErrorMessageProps = {
  errors: FieldError;
  message: string;
};

export function FormErrorMessage(props: FormErrorMessageProps) {
  const { errors, message } = props;

  const errorMessage = isEqual(errors?.type, 'pattern')
    ? 'Invalid input'
    : message;

  const formErrorMessage = errors ? (
    <small className="font-MuseoSans500 !text-overline text-left text-red-900">
      {errorMessage}
    </small>
  ) : (
    <Fragment />
  );

  return formErrorMessage;
}

export default FormErrorMessage;
